import React, { useState } from "react";
// import "../../components/main_global.css";
import CustomPopUp from "../custom-pop-up/custom_pop_up";
import { ServicesBanners } from "../../types/component_types";
import "./services_banner.css"
import { useTranslation } from "react-i18next";

const ServicesBanner = (props: ServicesBanners) => {
  const [showForm, setShowForm] = useState(false);
  const {t} = useTranslation();

  return (
    <div className="service-page-header">
      <div className="container">
        <div className="page-head-inner">
          <div className="page-head-info">
            <h2
              data-aos="fade-up"
              data-aos-duration="500"
              className="aos-init aos-animate"
            >
              {props.headingText}
            </h2>
            <p data-aos="fade-up" data-aos-duration="700" className="aos-init">
              {props.subHeading}
            </p>
            <button
              onClick={() => setShowForm(true)}
              className="btnClick"
              data-aos="fade-left"
              data-aos-duration="700"
            >
              {t('serviceBtn.AllService_btn')}
              <i className="las la-arrow-right"></i>
            </button>
            {showForm && <CustomPopUp onClick={() => setShowForm(false)} />}
          </div>
          <div className="page-head-img">
            <img
              alt="banner"
              width={632}
              height={564}
              src={props.src}
              data-aos="zoom-out"
              data-aos-duration="600"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesBanner;
