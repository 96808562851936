import { Box } from "@mui/material";
import React, { useState } from "react";
import Dashboard from "../../../admin/dashboard/Dashboard_Main";
import Dashboard_header from "../../../admin/dashboard-header/Dashboard_header";
import "./component.css";
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const Component = () => {
  // const [status, setStatus] = useState(false);
  // const handleSelect = () =>{
  //   setStatus(current => !current)
  // }

  const data = [
    {
      id: "1",

      inStatus: "Today worked on Angular",
      outTime: "8:30pm",

      projectName: "Angular",
      approvedMannager: "Gyan sir",
      wokedHours: "2Hr",
    },
    {
      id: "2",
 
      inStatus: "Today worked on react js",
      outTime: "8:30pm",

      projectName: "React Js",
      approvedMannager: "Gyan sir",
      wokedHours: "2Hr",
    },
    {
      id: "3",
      inStatus:"Today worked on react js is simply dummy text of the printing and typesetting industry.",
      outTime: "8:30pm",

      projectName: "Angular",
      approvedMannager: "Gyan sir",
      wokedHours: "2Hr",
    },
    {
      id: "4",

      inStatus: "Today worked on react js",
      outTime: "8:30pm",

      projectName: "Angular",
      approvedMannager: "Gyan sir",
      wokedHours: "2Hr",
    },
  ];
  const option = [
    {
      value: "select employee",
      tittle: "Select Employee",
    },
    {
      value: "bhanu",
      tittle: "Bhanu Kumar",
    },
    {
      value: "anshu",
      tittle: "Anshu Kumar",
    },
    {
      value: "neeraj",
      tittle: "Neeraj Kumar",
    },
  ];

  const dataContent = [
    {
      name: "Bhanu Partap",
      date: "24-01-2024",
      time: "8:20",
    },
    {
      name: "Anshu Maurya",
      date: "24-01-2024",
      time: "8:20",
    },
    {
      name: "Neeraj Singh",
      date: "24-01-2024",
      time: "8:20",
    },
  ];
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }

    setSelected(i);
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Dashboard_header />
      <Box height={55} />
      <Box sx={{ display: "flex" }}>
        <Dashboard />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <div className="timeSheet_heading">
            <h1 className="timeSheet_title">Approved time Sheet</h1>
          </div>
          <div className="timeSheet_continer">
            <div className="timeSheet_wrapper">
              <div className="timesheet">
                <form action="">
                  <div className="formBoxContiner"> 
                    <div className="formRow">
                      <div className="inputBox">
                        <label htmlFor="" className="formLabel">
                          Employee Name
                        </label>
                        <select
                          name="project"
                          id="projectName"
                          className="optinonValue"
                          // onChange={handleSelect}
                        >
                          {option.map((option, id) => (
                            <option value={option.value} key={id}>
                              {option.tittle}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="inputBox">
                        <label htmlFor="form date" className="formLabel">
                          Form Date
                        </label>
                        <input
                          type="date"
                          className="formTitle"
                          placeholder="name"
                          // value={date}
                          // onChange={(e) => setDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="inputBox">
                        <label htmlFor="to date" className="formLabel">
                          To Date
                        </label>
                        <input
                          type="date"
                          className="formTitle"
                          placeholder="name"
                          // value={date}
                          // onChange={(e) => setDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="approved_section">
            {dataContent.map((item, i) => (
              <div className="approved_continer" key={i}>
                <div className="tittle" onClick={() => toggle(i)}>
                  <div className="approved_team_list">{item.name}</div>
                  <div className="approved_team_list">{item.date}</div>
                  <div className="approved_team_list">{item.time}</div>
                  <div className="approved_team_list">
                    <button type="button" className="panddingbtn">
                      Pandding
                    </button>
                  </div>
                  <span className="approved_team_list">
                    {selected === i ? <KeyboardArrowDown/> : <KeyboardArrowUp/>}
                  </span>
                </div>

                <div
                  className={selected === i ? "content-show" : "contentWrapper"}
                >
                  <table>
                    <tbody>
                      {data.map((item,id) => (
                        <tr key={id}>
                          <td>{item.id}</td>
                          <td colSpan={2}>{item.projectName}</td>
                          <td colSpan={5}>{item.inStatus}</td>
                          <td>{item.outTime}</td>
                          <td>{item.approvedMannager}</td>
                          <td>{item.wokedHours}</td>

                          <td>
                            <button type="button" className="tableBtn">
                              Approved
                            </button>
                            <button
                              type="button"
                              className="tableBtnDel"
                              onClick={() => setShowModal(true)}
                            >
                              Reject
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
          {showModal ? (
            <div className="DisApproved_section">
              <div className="DisApproved_continer">
                <div className="DisApproved_icon">
                  <Close onClick={() => setShowModal(false)} />
                </div>
                <div className="DisApproved_content">
                  <div className="DisApproved_box">
                    <h4>Approved Reject</h4>
                  </div>
                  <label htmlFor="massage" className="massageLable">
                    Massage
                  </label>
                  <textarea
                    type="massage"
                    className="massageType"
                    placeholder="Enter a massage"
                    required
                  />
                  <button type="button" className="subbtn">
                    Send
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default Component;
