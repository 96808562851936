import React, { useRef, useState } from "react";
import Dashboard from "../../../admin/dashboard/Dashboard_Main";
import { Box } from "@mui/material";
import Dashboard_header from "../../../admin/dashboard-header/Dashboard_header";
import "./about_dahsboard.css";
import { ArrowBackIosNew, DeleteForever, EditNote } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const About_dashboard = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const [status, setStatus] = useState(false);

  const diffTost = () => {
    toast.success("Add Successfull");
    setStatus(true);
  };

  const [inputs, setInputs] = useState({
    name: "",
    date: "",
    starttime: "",
    endtime: "",
    hours: "",
    instatus: "",
    project: "",
  });
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const [tableData, setTableData] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("inputs", inputs)

    if (editClick) {
      const tempTableData = tableData;
      Object.assign(tempTableData[editIndex], inputs);
      setTableData([...tempTableData]);
      setEditClick(false);
      setInputs({
        name: "",
        date: "",
        starttime: "",
        endtime: "",
        hours: "",
        instatus: "",
        project: "",
      });
      alert("Succesfull Update");
    } else {
      setTableData([...tableData, inputs]);
      setInputs({
        name: "",
        date: "",
        starttime: "",
        endtime: "",
        hours: "",
        instatus: "",
        project: "",
      });
    }
  };
  console.log("tableData", tableData);

  const handleDelete = (index) => {
    const filterData = tableData.filter((item, i) => i !== index);
    setTableData(filterData);
    alert("SuccessFull Delete");
  };

  const [editClick, setEditClick] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const haldleEdit = (index) => {
    const tempData = tableData[index];
    setInputs({
      name: tempData.name,
      date: tempData.date,
      starttime: tempData.starttime,
      endtime: tempData.endtime,
      hours: tempData.hours,
      project: tempData.project,
    });
    setEditClick(true);
    setEditIndex(index);
  };

  About_dashboard.modules = {
    toolbar: [
      [
        { header: "1" },
        { header: "2" },
        // { header: [3, 4, 5, 6] },
        { font: [] },
      ],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
      ["code-block"],
    ],
  };
  About_dashboard.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
    "code-block"
  ];

  const [body, setBody] = useState("");
  const handleBody = (e) => {
    setBody(e)
  }

  return (
    <>
      <Dashboard_header />
      <Box height={55} />
      <Box sx={{ display: "flex" }}>
        <Dashboard />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <div className="timeSheet_heading">
            <h1 className="timeSheet_title">Report</h1>
          </div>
          <div className="timeSheet_continer">
            <div className="timeSheet_wrapper">
              <div className="timesheet form">
                <form onSubmit={handleSubmit}>
                  <div className="formBox">
                    <div className="formRow">
                      <div className="inputBox">
                        <label htmlFor="" className="formLabel">
                          Project Name
                        </label>
                        <select
                          name="project"
                          id="projectName"
                          value={inputs.project}
                          onChange={handleChange}
                          required
                        >
                          <option value="">--Select Project--</option>
                          <option className="optStyle">Angular</option>
                          <option>React Js</option>
                          <option>.Net</option>
                          <option>Next Js</option>
                          <option>React Native</option>
                          <option>HTML</option>
                          <option>CSS</option>
                        </select>
                      </div>
                      <div className="inputBox">
                        <label htmlFor="" className="formLabel">
                          Date
                        </label>
                        <input
                          type="date"
                          className="formTitle"
                          placeholder="name"
                          name="date"
                          value={inputs.date}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="inputBox">
                        <label htmlFor="" className="formLabel">
                          Approved Mannager
                        </label>
                        <input
                          type="name"
                          className="formTitle"
                          placeholder="Approved Mannager"
                          name="name"
                          value={inputs.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="formRow">
                      <div className="inputBox">
                        <label htmlFor="" className="formLabel">
                          Start Time
                        </label>
                        <input
                          type="time"
                          className="formTitle"
                          placeholder="in time"
                          name="starttime"
                          value={inputs.starttime}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="inputBox">
                        <label htmlFor="" className="formLabel">
                          End Time
                        </label>
                        <input
                          type="time"
                          className="formTitle"
                          placeholder="out time"
                          name="endtime"
                          value={inputs.endtime}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="inputBox">
                        <label htmlFor="" className="formLabel">
                          Worked Hours
                        </label>
                        <input
                          type="hours"
                          className="formTitle"
                          placeholder="Hours"
                          name="hours"
                          value={inputs.hours}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="formRow100">
                      <div className="inputBox">
                        <label htmlFor="" className="formLabel">
                          Today Work Report
                        </label>

                        {/* <textarea name="instatus" value={inputs.instatus}
                          onChange={handleChange} ></textarea> */}
                        <ReactQuill 
                        placeholder="Write a massage..."
                        modules={About_dashboard.modules}
                        formats={About_dashboard.formats}
                        onChange={handleBody}
                        value={body}
                        />
                      </div>
                    </div>
                    <div className="btn_wrapper">
                      <div className="employee_button">
                        <button
                          className="employee_btn"
                          type="submit"
                          onClick={diffTost}
                        >
                          {editClick ? "Update" : "Add To TimeSheet"}
                        </button>
                      </div>
                      {/* <ToastContainer /> */}
                      <div className="employee_button">
                        <button className="employee_btn_one" type="button">
                          Save to TimeSheet
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {status ? (
            <div className="table_section">
              <div className="table_continer">
                <table>
                  <thead>
                    <tr>
                      <th>S No</th>
                      <th>Date</th>
                      <th>Name</th>
                      <th>Cheack In</th>
                      <th>In Status</th>
                      <th>Project Name</th>
                      <th>Cheak Out</th>
                      <th>Approved Mannager</th>
                      <th>Worked Hours</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, i) => (
                      <tr key={item.id}>
                        <td>1</td>
                        <td>{item.date}</td>
                        <td>Bhanu Partap</td>
                        <td>{item.starttime}</td>
                        <td>Today is Worked on Angular</td>
                        <td>{item.project}</td>
                        <td>{item.endtime}</td>
                        <td>{item.name}</td>
                        <td>{item.hours}</td>
                        <td>
                          <button
                            type="button"
                            className="tableBtn"
                            onClick={() => haldleEdit(i)}
                          >
                            <EditNote />
                          </button>
                          <button
                            type="button"
                            className="tableBtnDel"
                            onClick={() => handleDelete(i)}
                          >
                            <DeleteForever />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="right_arrow">
                  <div className="right_arrow_icon">
                    <ArrowBackIosNew onClick={() => setStatus(false)} />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default About_dashboard;
