import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const JobsForm = () => {
  const skills = [
    "C#",
    "ASP.NET CORE",
    "Angular 16",
    "JSON",
    "SQL",
    "Telerik tools",
  ];
  const navigate = useNavigate();

  const handleApply = () => {
    navigate("/career");
  };

  const [isOpen, setIsOpen] = useState(false);
  const [showReadMoreButton, setShowReadMoreButton] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      console.log(ref.current.scrollheight, ref.current.clientHeight);
      setShowReadMoreButton(
        ref.current.scrollheight !== ref.current.clientHeight
      );
    }
  }, []);
  return (
    <div className="jobs-content">
      <div className="container px-4 py-5">
        <h2 className="pb-2 jobsTitle">Current Open Job Position</h2>
        <div className="card_continer">
          <div className="card_continer_wrapper">
            <div className="card_wrapper">
              <div className="cardSkill-wrapper">
                <div className="cardSkill_head">
                  <h4 className="card_heading">
                    Windows Application development
                  </h4>
                </div>
                <div className="cardSkill_title">
                  {/* <span className="cardExprience">Mix Ex - 5years </span> */}
                </div>
              </div>
              <div className="skillContnet-wrapper">
                <div className="skillTitle_content">
                  <p className="skillTitle">Requirement Skills :</p>
                </div>
                <div className="allSkill_desc">
                  {skills.map((skill) => (
                    <span className="skill_title">{skill}</span>
                  ))}
                </div>
              </div>
              {/* <div className="card_job_desc">
                <p
                  className={isOpen ? "paragrphStyle" : "card_desc_subTitle"}
                  ref={ref}
                >
                  Descripation : Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit. Delectus, ipsum mollitia perferendis
                  officiis iusto dolor quis eos quasi vel fugit, placeat
                  dignissimos! Maxime officiis incidunt tempore atque! Voluptate
                  officiis adipisci fugit doloribus iusto eos sunt sit. Alias
                  excepturi saepe temporibus? Lorem, ipsum dolor sit amet
                  consectetur adipisicing elit. Autem culpa harum, quas officiis
                  quaerat perspiciatis. Cum quibusdam explicabo magni
                  soluta?Lorem ipsum dolor sit amet, consectetur adipisicing
                  elit. Eum ducimus, maxime laboriosam possimus praesentium
                  eius! Doloremque ullam maxime cupiditate alias.
                </p>
                
              </div> */}
            </div>
            <div className="card_skill_left">
              <span className="cardExprience">
                Exp 3 - 5+ years{" "}
                <span className="location_tilte"> (Noida,Uttar Pradesh)</span>
              </span>
              <span className="cardExprience">Job Type: Full-time</span>
            </div>
          </div>
          <div className="card_job_desc">
            <p
              className={isOpen ? "paragrphStyle" : "card_desc_subTitle"}
              ref={ref}
            >
              Descripation : Require self motivated, team player 5+ years of
              experience in C# development experiance for an off-shore client to
              develop an industry scaled application. Key skills required for
              the position are.<br/> 1. Candidate should have good communication
              skill in English language.<br/> 2. Must have good knowledge and at
              least 5 years of working experience with Windows Desk ASP.NET
              4.8.1 C# 5 + years . Telerik Controls big plus<br/> 3. Required to have
              good knowledge of JSON and ability to consume Rest API 's.<br/> 4.
              Ability to consume API from companies like ADP
              https://developers.adp.com/ and or google maps<br/> 5. Knowledge and
              experience in working with ASP.NET Forms C# with Telerik Controls
              big plus<br/> 6. Knowledge of ASP.NET CORE and experience working with
              Razor pages is added advantage.<br/> 7. Should have experience with
              Transact SQL 5 + years , tables , procedures , views , triggers ,
              functions<br/> 8. VBA , Excel Macros , formulas, Quick Books API,
              understanding of Quick Books ODBC big plus<br/> 9. Knowledge of Angular
              16 big plus
            </p>
          </div>
          <span className="location_tilte"> Work Location: Noida, Sector 58</span>
          {showReadMoreButton && (
            <div className="cardLeft_btn">
              <button
                type="submit"
                className="readBtn"
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? "Read Less" : "Read More"}
              </button>
              <button type="submit" className="applyBtn">
                <a href="mailto:codemetrics.in">Apply Now</a>
                {/* Apply Now */}
              </button>
            </div>
          )}
        </div>

        <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5"></div>
      </div>
    </div>
  );
};

export default JobsForm;
