import React from "react";
import ServicesBanner from "../../../reusableScreens/service-banner/services_banner";
import Management from "../../../reusableScreens/management/management";
import ContactForm from "../../../reusableScreens/contact-form/contact_form";
import servicsImg1 from "../../../../assets/images/mobile-app-development.png";
import HeaderNav from "../../../header/header_nav";
import Footer from "../../../footer/Footer";
import { useTranslation } from "react-i18next";

const MobileApp = () => {

const {t} = useTranslation();

  return (
    <>
      <HeaderNav />
      <ServicesBanner
        headingText={t('services.mobileApp_serviceBanner_heading')}
        subHeading={t('services.mobileApp_serviceBanner_title')}
        src={servicsImg1}
      />
      <Management />
      <ContactForm />
      <Footer />
    </>
  );
};

export default MobileApp;
