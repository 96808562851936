import React from "react";
import "../../main_global.css";
import IndustriesBanner from "../../reusableScreens/industries/industries_banner";
import ManageSecurity from "../../reusableScreens/manage-security/manage_security";
import ContactForm from "../../reusableScreens/contact-form/contact_form";
import HeaderNav from "../../header/header_nav";
import Footer from "../../footer/Footer";
import { useTranslation } from "react-i18next";

const Transportation = () => {
  const {t} =useTranslation();
  return (
    <>
    <HeaderNav/>
      <IndustriesBanner
        workStyle="work-page-header contact-head industries-bg transportation-bg"
        contactTitle={t('transportation.transportation_contactTitle')}
        contactHeading={t('transportation.transportation_contactHeading')}
        contactSubheading={t('transportation.transportation_contactSubheading')}
      />
      <ManageSecurity />
      <ContactForm />
      <Footer/>
    </>
  );
};

export default Transportation;
