import React from "react";
import {
  LiaComments,
  LiaDonateSolid,
  LiaFolder,
  LiaRouteSolid,
  LiaSave,
  LiaUserLockSolid,
  LiaUserTieSolid,
} from "react-icons/lia";
import {  AiFillFolderOpen } from "react-icons/ai";
import SecurityCard from "../manage-security/security_card";
import "./management.css"
import { useTranslation } from "react-i18next";

const Management = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="everything-need">
        <div className="container">
          <h2
            data-aos="fade-in"
            data-aos-duration="700"
            className="aos-init aos-animate"
          >
            {t('services_management.management_heading')}
          </h2>
          <div className="needs-list">
            <div
              className="needs-item aos-init aos-animate"
              data-aos="zoom-out"
              data-aos-duration="500"
            >
              <i className="las la-comments">
                <LiaComments />
              </i>
              <span className="spanItem">{t('services_management.management_itemOne')}</span>
            </div>
            <div
              className="needs-item aos-init aos-animate"
              data-aos="zoom-out"
              data-aos-duration="700"
            >
              <i className="las la-user-tie">
                <LiaUserTieSolid />
              </i>
              <span className="spanItem">{t('services_management.management_itemTwo')}</span>
            </div>
            <div
              className="needs-item aos-init aos-animate"
              data-aos="zoom-out"
              data-aos-duration="900"
            >
              <i className="las la-user-lock">
                <LiaUserLockSolid />
              </i>
              <span className="spanItem">{t('services_management.management_itemThree')}</span>
            </div>
            <div
              className="needs-item aos-init aos-animate"
              data-aos="zoom-out"
              data-aos-duration="1100"
            >
              <i className="las la-save">
                <LiaSave />
              </i>
              <span className="spanItem">{t('services_management.management_itemFour')}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="service-items">
        <div className="container px-4 py-5" id="featured-3">
          <h2 className="pb-2" data-aos="fade-in" data-aos-duration="700">
          {t('serviceItem.servicesItem_heading')}
          </h2>
          <p
            className="border-bottom securitySubtitle"
            data-aos="fade-in"
            data-aos-duration="900"
          >
            {t('serviceItem.servicesItem_title')}
          </p>
          <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
            <SecurityCard
              // icon={<LiaFolder />}
              icon={<LiaFolder/>}
              cardText={t('serviceItem.servicesItem_cardTextOne')}
              cardSubText={t('serviceItem.servicesItem_cardSubTextOne')}
            />

            <SecurityCard
              // icon={<LiaRouteSolid />}
              icon={<LiaRouteSolid/>}
              cardText={t('serviceItem.servicesItem_cardTextTwo')}
              cardSubText={t('serviceItem.servicesItem_cardSubTextTwo')}
            />

            <SecurityCard
              // icon={<LiaDonateSolid />}
              icon={<LiaDonateSolid/>}
              cardText={t('serviceItem.servicesItem_cardTextThree')}
              cardSubText={t('serviceItem.servicesItem_cardSubTextThree')}
            />

            <SecurityCard
              // icon={<LiaFolder />}
              icon={<LiaFolder/>}
              cardText={t('serviceItem.servicesItem_cardTextFour')}
              cardSubText={t('serviceItem.servicesItem_cardSubTextFour')}
            />

            <SecurityCard
              // icon={<LiaRouteSolid />}
              icon={<AiFillFolderOpen/>}
              cardText={t('serviceItem.servicesItem_cardTextFive')}
              cardSubText={t('serviceItem.servicesItem_cardSubTextFive')}
            />

            <SecurityCard
              // icon={<LiaDonateSolid />}
              icon={<LiaDonateSolid/>}
              cardText={t('serviceItem.servicesItem_cardTextSix')}
              cardSubText={t('serviceItem.servicesItem_cardSubTextSix')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Management;
