import "./deliver-value.css";
import { useTranslation } from "react-i18next";

const Delivervalue = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="deliver-value">
        <div className="container px-4 py-5">
          <h2 className="pb-2 fw-bold" data-aos="fade-right">
            {t("deliverValue.deliverValue_heading")}
          </h2>
          <p data-aos="fade-right" className="deliver-subtitle">
            {t("deliverValue.deliverValue_title")}
          </p>
          <div className="row align-items-start">
            <div
              className="col value-box"
              data-aos="flip-down"
              data-aos-duration="500"
            >
              <h3>{t("deliverValue.deleverValue_numberOne")}</h3>
              <p>{t("deliverValue.deleverValue_year")}</p>
            </div>
            <div
              className="col value-box"
              data-aos="flip-down"
              data-aos-duration="600"
            >
              <h3>{t("deliverValue.deleverValue_numberTwo")}</h3>
              <p>{t("deliverValue.deleverValue_SubTitle")}</p>
            </div>
            <div
              className="col value-box"
              data-aos="flip-down"
              data-aos-duration="700"
            >
              <h3>{t("deliverValue.deleverValue_numberThree")}</h3>
              <p>{t("deliverValue.deleverValue_Success")}</p>
            </div>
            <div
              className="col value-box"
              data-aos="flip-down"
              data-aos-duration="800"
            >
              <h3>{t("deliverValue.deleverValue_numberFour")}</h3>
              <p>{t("deliverValue.deleverValue_Rate")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Delivervalue;
