import React from "react";
import { CardComponent } from "../types/component_types";

import { FiArrowRight } from "react-icons/fi";
import "./card.css"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Card = (props: CardComponent) => {
  const {t} = useTranslation();
  return (
    <div className="col" data-aos="zoom-out" data-aos-duration="500">
      <div className="card card-cover h-100 overflow-hidden rounded-2 no-border">
        <div className="d-flex flex-column h-100 p-5  text-white text-shadow-1">
          <ul className="d-flex list-unstyled">
            <li className="me-auto">
              <img alt="cardImage" width={38} height={55} src={props.src} />
            </li>
          </ul>
          <h4 className="service-title">{props.serviceTitle}</h4>
          <p className="service-sub">{props.subTitle}</p>
          <Link to={props.sendto}>
          <a>
            {t('cardButton.cardBtn')}
            <i className="las la-long-arrow-alt-right">
              <FiArrowRight />
            </i>
            </a>
          </Link>
        </div>
      </div>
      
    </div>
    
  );
};

export default Card;