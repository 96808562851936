import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/main_global.css";
import "../src/components/screens/landingPage/main-page.css";
import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import Work from "./components/pages/home/work";
import AboutUs from "./components/pages/about-us/about_us";
import Blog from "./components/pages/blog/blog";
import Career from "./components/pages/career/carrer";
import ContactUs from "./components/pages/contact-us/contact_us";
import Ecommerce from "./components/pages/ecommerce/ecommerce";
import Education from "./components/pages/education/education";
import Finance from "./components/pages/finance/finance";
import Industries from "./components/pages/industries/industries";
import CustomPopUp from "./components/reusableScreens/custom-pop-up/custom_pop_up";
import Sass from "./components/pages/sass/sass";
import Maintenance from "./components/pages/services/maintenance/maintenance";
import MobileApp from "./components/pages/services/mobile-dev/mobile_dev";
import MobGames from "./components/pages/services/mobile-games/mobile_games";
import SoftTesting from "./components/pages/services/soft-testing/soft_testing";
import UiUxDesign from "./components/pages/services/ui-ux-design/ui_ux_design";
import WebDev from "./components/pages/services/web-dev/web_dev";
import SignInForm from "./components/pages/sign-in/sign_in";
import SocialMedia from "./components/pages/social-media/social_media";
import Transportation from "./components/pages/transportation/transportation";
import HeaderNav from "./components/header/header_nav";
import Home from "./components/pages/home-main-page/home";
// import Aos from "aos";
// import "aos/dist/aos.css";
import Footer from "./components/footer/Footer";
import Dashboard_main from "./components/pages/dashboard-main/Dashboard";
import Side_bar from "./components/pages/side-bar-content/side-bar/Side_bar";
import About_dashboard from "./components/pages/side-bar-content/about/About_dashboard";
import Component from "./components/pages/side-bar-content/component/Component";
import Dashboard from "./components/admin/dashboard/Dashboard_Main";
import ScrollToTop from "./components/types/ScrollToTop";
import Search from "./components/admin/search/Search";
import ContactDetails from "./components/pages/side-bar-content/contactDetails/ContactDetails";
import Employee_timeSheet from "./components/pages/side-bar-content/employee/Employee_timeSheet";
import ApplicationForm from "./components/pages/side-bar-content/applicationForm/ApplicationForm";
import Jobs from "./components/pages/jobs/Jobs";
import Clock from "./components/pages/side-bar-content/jobs/Clock";
function App() {
  // useEffect(() => {
  //   Aos.init({
  //     duration: 800,
  //     once: false,
  //   });
  // });
  return (
    <HashRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/work" element={<Work />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/ecommerce" element={<Ecommerce />} />
          <Route path="/education" element={<Education />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/custompopup" element={<CustomPopUp />} />
          <Route path="/sass" element={<Sass />} />
          <Route path="/services/maintenance" element={<Maintenance />} />
          <Route path="/services/mobiledev" element={<MobileApp />} />
          <Route path="/services/mobGames" element={<MobGames />} />
          <Route path="/services/softTesting" element={<SoftTesting />} />
          <Route path="/services/uiUxDesign" element={<UiUxDesign />} />
          <Route path="/services/webDev" element={<WebDev />} />
          <Route path="/signin" element={<SignInForm />} />
          <Route path="/socialmedia" element={<SocialMedia />} />
          <Route path="/transportation" element={<Transportation />} />
          <Route path="/side_bar" element={<Side_bar />} />
          <Route path="/about_dashboard" element={<About_dashboard />} />
          <Route path="/search" element={<Search />} />
          <Route path="/component" element={<Component />} />
          <Route path="/contactDetails" element={<ContactDetails />} />
          <Route path="/employee_timesheet" element={<Employee_timeSheet />} />
          <Route path="/employee_timesheet" element={<Clock />} />
          <Route path="/applicationForm" element={<ApplicationForm />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </ScrollToTop>
    </HashRouter>
  );
}

export default App;
