import Footer from "../../footer/Footer";
import HeaderNav from "../../header/header_nav";
import ContactForm from "../../reusableScreens/contact-form/contact_form";
import IndustriesBanner from "../../reusableScreens/industries/industries_banner";
import ManageSecurity from "../../reusableScreens/manage-security/manage_security";
import { useTranslation } from "react-i18next";

const Sass = () => {
  const {t}=useTranslation();
  return (
    <>
    <HeaderNav/>
      <IndustriesBanner
        workStyle="work-page-header contact-head industries-bg saas-bg"
        contactTitle={t('sass.sass_contactTitle')}
        contactHeading={t('sass.sass_contactHeading')}
        contactSubheading={t('sass.sass_contactSubheading')}
      />
      <ManageSecurity />
      <ContactForm />
      <Footer/>
    </>
  );
};

export default Sass;
