import React from "react";
import SecBanner from "../../reusableScreens/sec-banner/sec_banner";
import CarrerScreenForm from "../../screens/screen-forms/career_screen_form";
import HeaderNav from "../../header/header_nav";
import Footer from "../../footer/Footer";
import { useTranslation } from "react-i18next";

const Career = () => {
  const {t} = useTranslation();
  return (
    <>
    <HeaderNav/>
      <SecBanner
        pageTitle={t('carrerSecBanner.carrerSecBanner_pageTitle')}
        headingText={t('carrerSecBanner.carrerSecBanner_headingText')}
        headingTextBold={t('carrerSecBanner.carrerSecBanner_headingTextBold')}
        subTitle={t('carrerSecBanner.carrerSecBanner_subTitle')}
      />
      <CarrerScreenForm />
      <Footer/>
    </>
  );
};

export default Career;
