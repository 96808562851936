import React from "react";
import ContactCardDetails from "../../cards/contact_card_details";
import ContactScreenForm from "../../screens/screen-forms/contact_screen_form";
import "./contact.css";
import HeaderNav from "../../header/header_nav";
import Footer from "../../footer/Footer";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderNav />
      <div className="work-page-header contact-head">
        <div className="container">
          <div className="work-head-inner">
            <div className="work-head-info">
              <label
                data-aos="fade-up"
                data-aos-duration="500"
                className="aos-init aos-animate"
              >
                {t("contactUs.contact_label")}
              </label>
              <h2
                data-aos="fade-up"
                data-aos-duration="600"
                className="aos-init aos-animate"
              >
                {t("contactUs.contact_heading")}
              </h2>
              <p
                data-aos="fade-up"
                data-aos-duration="800"
                className="aos-init"
              >
                {t("contactUs.contact_title")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <ContactCardDetails />
      <ContactScreenForm />
      <Footer />
    </>
  );
};

export default ContactUs;
