import { IndustryLeftProp } from "../../types/component_types";
import React, { useState } from "react";
import CustomPopUp from "../custom-pop-up/custom_pop_up";
// import "../../main_global.css";
import "./industries.css"
import { useTranslation } from "react-i18next";

const IndustriesDetailsLeft = (props: IndustryLeftProp) => {
  const [showForm, setShowForm] = useState(false);
  const {t} =useTranslation();

  return (
    <>
      <div className="industry-row white-row">
        <div className="container">
          <div className="industry-inner">
            <div className="industry-image">
              <img
                alt="industryImg"
                width={741}
                height={541}
                src={props.src}
                data-aos="fade-out"
                data-aos-duration="900"
                className="aos-init aos-animate"
              />
            </div>
            <div className="industry-content-title">
              <h3
                data-aos="fade-up"
                data-aos-duration="500"
                className="aos-init aos-animate"
              >
                {props.contentTitle}
              </h3>
              <p
                data-aos="fade-up"
                data-aos-duration="700"
                className="aos-init aos-animate industriesSubTitle"
              >
                {props.contentParagraph}
              </p>
              <ul
                data-aos="fade-up"
                data-aos-duration="900"
                className="aos-init aos-animate"
              >
                <li>{t('industriesitem.industriesitem_listOne')}</li>
                <li>{t('industriesitem.industriesitem_listTwo')}</li>
                <li>{t('industriesitem.industriesitem_listThree')}</li>
                <li>{t('industriesitem.industriesitem_listFour')}</li>
                <li>{t('industriesitem.industriesitem_listFive')}</li>
              </ul>
              <div className="content-info aos-init">
                <button
                  onClick={() => setShowForm(true)}
                  // className="btnClick"
                  className="bash"
                  data-aos="fade-left"
                  data-aos-duration="700"
                >
                  <span>
                  {t('industriesitem.industriesitem_btn')}
                    <i className="las la-long-arrow-alt-right arrow-icon">
                      {props.icons}
                    </i>
                  </span>
                  
                </button>
                {showForm && <CustomPopUp onClick={() => setShowForm(false)} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndustriesDetailsLeft;
