import React from "react";

import { BsArrowRight } from "react-icons/bs";
import { GiCircle } from "react-icons/gi";
import SecBanner from "../../reusableScreens/sec-banner/sec_banner";
import IndustriesDetials from "../../reusableScreens/industries-all-details/industriesdetails";
import IndustriesDetailsLeft from "../../reusableScreens/industries-all-details/industries_details_left";
import ContactForm from "../../reusableScreens/contact-form/contact_form";
import industriesImg1 from "../../../assets/images/industry-1.png";
import industriesImg2 from "../../../assets/images/industry-2.png";
import industriesImg3 from "../../../assets/images/industry-3.png";
import industriesImg4 from "../../../assets/images/industry-4.png";
import HeaderNav from "../../header/header_nav";
import Footer from "../../footer/Footer";
import { useTranslation } from "react-i18next";

const Industries = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderNav />
      <SecBanner
        pageTitle={t("industries.secbanner_title")}
        headingText={t("industries.secbanner_headingText")}
        headingTextBold={t("industries.secbanner_headdingText_bold")}
        subTitle={t("industries.secbanner_subTitle")}
      />
      <IndustriesDetials
        contentTitle={t("industriesDetials.industriesDetails_contentTitle")}
        contentParagraph={t(
          "industriesDetials.industriesDetails_contentParagraph"
        )}
        src={industriesImg1}
        icons={<BsArrowRight />}
        iconsOne={<GiCircle fontSize={45} />}
      />
      <IndustriesDetailsLeft
        contentTitle={t("industriesDetialsLeft.industriesDetails_contentTitle")}
        contentParagraph={t(
          "industriesDetialsLeft.industriesDetails_contentParagraph"
        )}
        src={industriesImg2}
        icons={<BsArrowRight />}
        iconsOne={<GiCircle fontSize={45} />}
      />
      <IndustriesDetials
        contentTitle={t("industriesDetialsTwo.industriesDetails_contentTitle")}
        contentParagraph={t(
          "industriesDetialsTwo.industriesDetails_contentParagraph"
        )}
        src={industriesImg3}
        icons={<BsArrowRight />}
        iconsOne={<GiCircle fontSize={45} />}
      />
      <IndustriesDetailsLeft
        contentTitle={t(
          "industriesDetialsLeftTwo.industriesDetails_contentTitle"
        )}
        contentParagraph={t(
          "industriesDetialsLeftTwo.industriesDetails_contentParagraph"
        )}
        src={industriesImg4}
        icons={<BsArrowRight />}
        iconsOne={<GiCircle fontSize={45} />}
      />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Industries;
