import Member from "./member";
import { member } from "../../../dummy-data/dummy_data";
import "./member-card.css"
import { useTranslation } from "react-i18next";

const MemberCard = () => {
  const {t} = useTranslation();
  return (
    <section className="pt-5 pb-5 team-container-main">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-9 team-title-head">
            <h2 className="pb-2 fw-bold" data-aos="fade-right">
              {t('memberCard.memberCard_heading')}
            </h2>
            <p data-aos="fade-right" className="teamMember-subtitle">
            {t('memberCard.memberCard_title')}
            </p>
          </div>

          <div className="col-12">
            <div className="row">
              {member.map((data) => (
                <Member
                  key={data.id}
                  src={data.src}
                  memberName={data.memberName}
                  memberRole={data.memberRole}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MemberCard;
