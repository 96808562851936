import { BannerProps } from "../../types/component_types";
import React from "react";
import "./industries_banner.css"
const IndustriesBanner = (props: BannerProps) => {
  return (
    <>
      <div className={props.workStyle}>
        <div className="container">
          <div className="work-head-inner">
            <div className="work-head-info">
              <label
                data-aos="fade-up"
                data-aos-duration="500"
                className="aos-init aos-animate"
              >
                {props.contactTitle}
              </label>
              <h2
                data-aos="fade-up"
                data-aos-duration="600"
                className="aos-init aos-animate"
              >
                {props.contactHeading}
              </h2>
              <p
                data-aos="fade-up"
                data-aos-duration="800"
                className="aos-init"
              >
                {props.contactSubheading}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndustriesBanner;
