// import React, { useState } from "react";
import "./popup.css";
import { LiaDonateSolid } from "react-icons/lia";
import { PiInfinity } from "react-icons/pi";
import { RxCross1 } from "react-icons/rx";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { siteKeys } from "../../../fakeData/Recaptcha";
import { useTranslation } from "react-i18next";


const CustomPopUp = (props) => {
  const onChange = () => {};

  const [popUpInputs, setPopUpInputs] = useState({
    name: "",
    email: "",
    phoneNo: "",
    massage: "",
  });

  const handlePopUpChange = (e) => {
    setPopUpInputs({
      ...popUpInputs,
      [e.target.name]: e.target.value,
    });
  };

  const [popUpTableData, setPopUpTableData] = useState([]);

  const handlePopUpSubmit = (e) => {
    e.preventDefault();
    setPopUpTableData([...popUpTableData, popUpInputs]);

    setPopUpInputs({
      name: "",
      email: "",
      phoneNo: "",
      massage: "",
    });
  };
  console.log("popUpTableData", popUpTableData);

  const {t} =useTranslation();

  return (
    <>
      <div
        className="popupContainer"
        data-aos="fade-down"
        data-aos-duration="400"
      >
        <div className="continer_box">
          {/* <div className="form-inner"> */}
          <div className="popupForm">
            <div className="content">
              <div className="title">
                <h5>
                  <LiaDonateSolid
                    fontSize={33}
                    color="red"
                    className="iconBox"
                  />
                  {t("contactForm.contactForm_detials_headingOne")}
                </h5>
                <p>
                {t("contactForm.contact_details_titleOne")}
                </p>
              </div>
              <div className="title">
                <h5>
                  <PiInfinity fontSize={33} color="red" className="iconBox" />
                  {t("contactForm.contactForm_detials_headingTwo")}
                </h5>
                <p>
                {t("contactForm.contact_details_titleTwo")}
                </p>
              </div>
              <div className="title">
                <h5>
                  <LiaDonateSolid
                    fontSize={33}
                    color="red"
                    className="iconBox"
                  />
                  {t("contactForm.contactForm_detials_headingThree")}
                </h5>
                <p>
                {t("contactForm.contact_details_titleThree")}
                </p>
              </div>
            </div>
            <div className="company-contact-form-one">
              <RxCross1 className="cross" onClick={props.onClick} />
              <div className="head">
                <h4>
                  {t('contactForm.get_in')} <span>{t('contactForm.touch')}</span>
                </h4>
              </div>
              <p>{t("contactForm.contact_touch_title")}</p>

              <form className="form-container" onSubmit={handlePopUpSubmit}>
                <div className="col-12 form-row-inner">
                  <label htmlFor="inputAddress" className="form-label">
                  {t("contactForm.contact_label_name")}
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={popUpInputs.name}
                    className="form-control"
                    id="inputAddress"
                    placeholder={t("contactForm.conatct_placeHolder_name")}
                    onChange={handlePopUpChange}
                    required
                  />
                </div>

                <div className="col-12 form-row-inner">
                  <label htmlFor="inputAddress" className="form-label">
                  {t("contactForm.contact_label_email")}
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={popUpInputs.email}
                    className="form-control"
                    id="inputAddress"
                    placeholder={t("contactForm.conatct_placeHolder_email")}
                    onChange={handlePopUpChange}
                    required
                  />
                </div>
                <div className="col-12 form-row-inner">
                  <label htmlFor="inputAddress" className="form-label">
                  {t("contactForm.contact_label_phone")}
                  </label>
                  <input
                    type="text"
                    name="phoneNo"
                    value={popUpInputs.phoneNo}
                    className="form-control"
                    id="inputAddress"
                    placeholder={t("contactForm.conatct_placeHolder_phone")}
                    onChange={handlePopUpChange}
                    required
                  />
                </div>
                <div className="col-12 form-row-inner">
                  <label htmlFor="inputAddress" className="form-label">
                  {t("contactForm.contact_label_massage")}
                  </label>
                  <textarea
                    name="massage"
                    value={popUpInputs.massage}
                    className="form-control"
                    placeholder={t("contactForm.conatct_placeHolder_massage")}
                    onChange={handlePopUpChange}
                    required
                  ></textarea>
                </div>
                {siteKeys.map((item, id) => (
                  <div className="recaptcha" key={id}>
                    <ReCAPTCHA
                      sitekey={item.sitkeyno}
                      onChange={onChange}
                    />
                  </div>
                ))}

                <div className="col-12 form-row-inner">
                  <button className="btn btn-danger">
                  {t("contactForm.contact_btn")}<i className="las la-arrow-right"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default CustomPopUp;
