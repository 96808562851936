import React, { useState } from "react";
import "./information.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Information = () => {
  const { t } = useTranslation();

  const [activeRow, setActiveRow] = useState(null);

  const showRow = (rowName: any) => {
    setActiveRow((prevRow: any) => (prevRow === rowName ? null : rowName));
  };

  return (
    <div className="faq-container">
      <div className="container">
        <h2 className="faq-title">{t("about_Information.about_faqs_title")}</h2>
        <div className="faq-group">
          <div className="faq-left">
            <h3>{t("about_Information.about_information_heading")}</h3>
            <p>{t("about_Information.about_Information_title")}</p>
            <p>{t("about_Information.about_Information_subTitle")}</p>
            <div className="faq-btns">
              <Link to="/contactUs" className="faq-btn">
                {t("about_Information.about_Information_btn")}
              </Link>
            </div>
          </div>

          <div className="faq-right">
            <h3>{t("about_Information.information_shoping")}</h3>
            <div className="faq-main">
              <div className="faq-item">
                <div className="faq-label">
                  {t("about_Information.about_label")}
                  <i onClick={() => showRow("row1")}></i>
                </div>

                {activeRow === "row1" && (
                  <div>
                    <p>{t("about_Information.information_titleOne")}</p>
                    <p>{t("about_Information.information_subTileOne")}</p>
                  </div>
                )}
              </div>

              <div className="faq-item">
                <div className="faq-label">
                  {t("about_Information.about_label_row")}
                  <i onClick={() => showRow("row2")}></i>
                </div>

                {activeRow === "row2" && (
                  <div>
                    <p>{t("about_Information.information_rowTitle")}</p>
                    <p>{t("about_Information.information_RowSubTitle")}</p>
                  </div>
                )}
              </div>

              <div className="faq-item">
                <div className="faq-label">
                  {t("about_Information.information_item_label")}
                  <i onClick={() => showRow("row3")}></i>
                </div>

                {activeRow === "row3" && (
                  <div>
                    <p>{t("about_Information.information_rowParagraph")}</p>
                  </div>
                )}
              </div>

              <div className="faq-item">
                <div className="faq-label">
                  {t("about_Information.about_labelTwo")}
                  <i onClick={() => showRow("row4")}></i>
                </div>
                {activeRow === "row4" && (
                  <div>
                    <p>{t("about_Information.information_paragraphTwo")}</p>
                  </div>
                )}
              </div>

              <div className="faq-item">
                <div className="faq-label">
                  {t("about_Information.about_label")}{" "}
                  <i onClick={() => showRow("row5")}></i>
                </div>
                {activeRow === "row5" && (
                  <div>
                    <p>{t("about_Information.information_rowTitle")}</p>
                    <p>{t("about_Information.information_rowTitle")}</p>
                  </div>
                )}
              </div>
            </div>
            <h3>{t("about_Information.information_payment_heading")}</h3>
            <div className="faq-main">
              <div className="faq-item">
                <div className="faq-label">
                  {t("about_Information.about_label_row")}
                  <i onClick={() => showRow("row6")}></i>
                </div>

                {activeRow === "row6" && (
                  <div>
                    <p>{t("about_Information.information_payment_rowTitle")}</p>
                    <p>{t("about_Information.information_payment_rowTitle")}</p>
                  </div>
                )}
              </div>
              <div className="faq-item">
                <div className="faq-label">
                  {t("about_Information.about_label")}
                  <i onClick={() => showRow("row7")}></i>
                </div>

                {activeRow === "row7" && (
                  <div>
                    <p>
                    {t("about_Information.information_paymentData")}
                    </p>
                    <p>{t("about_Information.information_payment_rowTitle")}</p>
                  </div>
                )}
              </div>

              <div className="faq-item">
                <div className="faq-label">
                  {t("about_Information.about_labelTwo")}
                  <i onClick={() => showRow("row8")}></i>
                </div>

                {activeRow === "row8" && (
                  <div>
                    <p>{t("about_Information.information_payment_rowTitle")}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
