// import "../../../../components/main_global.css"
import "./member-card.css"
import { Members } from "../../../types/component_types";

const Member = (props: Members) => {
  return (
    <div className="col-md-4 mb-3" data-aos="zoom-out" data-aos-duration="500">
      <div className="card p-3 team-card">
        <div className="team-box">
          <img
            width={356}
            height={403}
            alt="memberImage"
            src={props.src}
            className="team-image"
          />
          <div className="team-names">
            <h5>{props.memberName}</h5>
            <p>{props.memberRole}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Member;
