
import React from "react";
import ServicesBanner from "../../../reusableScreens/service-banner/services_banner";
import Management from "../../../reusableScreens/management/management";
import ContactForm from "../../../reusableScreens/contact-form/contact_form";
import servicsImg4 from "../../../../assets/images/software-testing-banner.png"
import HeaderNav from "../../../header/header_nav";
import Footer from "../../../footer/Footer";
import { useTranslation } from "react-i18next";

const SoftTesting = () => {
  const {t} = useTranslation();
  return (
    <>
    <HeaderNav/>
      <ServicesBanner
        headingText={t('servicesSoftTesting.softTesting_servicesBanner_headingText')}
        subHeading={t('servicesSoftTesting.softTesting_servicesBanner_subHeading')}
        src={servicsImg4}
      />
      <Management />
      <ContactForm />
      <Footer/>
    </>
  );
};

export default SoftTesting;