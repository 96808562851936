import { Box } from "@mui/material";
import React from "react";
import Dashboard from "../../../admin/dashboard/Dashboard_Main";
import Dashboard_header from "../../../admin/dashboard-header/Dashboard_header";

const Clock = () => {
  return (
    <>
      <Dashboard_header />
      <Box height={55}/>
      <Box sx={{ display: "flex" }}>
        <Dashboard />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <h1>Clock page</h1>
        </Box>
      </Box>
    </>
  );
};

export default Clock;
