import React, { useState } from "react";
import "./contact_screen.css";
import ReCAPTCHA from "react-google-recaptcha";
import { PiCurrencyBtc } from "react-icons/pi";
import { LiaChalkboardSolid, LiaMapPinSolid } from "react-icons/lia";
import { FaRssSquare } from "react-icons/fa";
import { siteKeys } from "../../../fakeData/Recaptcha";
import { useTranslation } from "react-i18next";

const ContactScreenForm = () => {

  const onChange = () => {};
  const {t} = useTranslation();

  const [contactInputs, setContactInputs] = useState({
    name: "",
    email: "",
    phoneNo: "",
    massage: "",
  });

  const handleContactChange = (e) => {
    setContactInputs({
      ...contactInputs,
      [e.target.name]: e.target.value,
    });
  };

  const [contactTableData, setContactTableData] = useState([]);

  const handleContactSubmit = (e) => {
    e.preventDefault();
    setContactTableData([...contactTableData, contactInputs]);

    setContactInputs({
      name: "",
      email: "",
      phoneNo: "",
      massage: "",
    });
  };
  console.log("contactTableData", contactTableData);

  return (
    <div className="connect-information">
      <div className="container">
        <div className="contact-form-container">
          <div className="contact-info">
            <h3
              data-aos="fade-up"
              data-aos-duration="500"
              className="aos-init aos-animate"
            >
              {t('contactScreenForm.contactScreen_heading')}
            </h3>
            <p data-aos="fade-up" data-aos-duration="700" className="aos-init">
            {t('contactScreenForm.contactScreen_title')}
            </p>
            <div className="contact-list">
              <div
                className="line-item aos-init"
                data-aos="fade-up"
                data-aos-duration="900"
              >
                <i className="las la-rss-square">
                  <FaRssSquare />
                </i>
                {t('contactScreenForm.contactScreen_list')}
              </div>
              <div
                className="line-item aos-init"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <i className="las la-chalkboard">
                  <LiaChalkboardSolid />
                </i>
                {t('contactScreenForm.contactScreen_listTwo')}
              </div>
              <div
                className="line-item aos-init"
                data-aos="fade-up"
                data-aos-duration="1300"
              >
                <i className="lab la-btc">
                  <PiCurrencyBtc />
                </i>
                {t('contactScreenForm.contactScreen_listThree')}
              </div>
            </div>

            <div className="office-address">
              <h3
                data-aos="fade-up"
                data-aos-duration="1400"
                className="aos-init"
              >
                {t('contactScreenForm.contactScreen_address')}
              </h3>
            </div>
            <div className="contact-list">
              <div
                className="line-item aos-init"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <i className="las la-map-pin">
                  <LiaMapPinSolid />
                </i>
                {t('contactScreenForm.contactScreen_allAddress')}
              </div>
            </div>
          </div>
          <div
            className="contact-form-inner aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <h3>{t('contactForm.contact_touch')}</h3>
            <p>{t('contactForm.contact_touch_title')}</p>
            <form className="form-container" onSubmit={handleContactSubmit}>
              <div className="col-12 form-row-inner">
                <label htmlFor="inputAddress" className="form-label">
                {t('contactForm.contact_label_name')}
                </label>
                <input
                  type="text"
                  name="name"
                  value={contactInputs.name}
                  className="form-control"
                  id="inputAddress"
                  placeholder={t('contactForm.conatct_placeHolder_name')}
                  onChange={handleContactChange}
                  required
                />
              </div>

              <div className="col-12 form-row-inner">
                <label htmlFor="inputAddress" className="form-label">
                {t('contactForm.contact_label_email')}
                </label>
                <input
                  type="text"
                  name="email"
                  value={contactInputs.email}
                  className="form-control"
                  id="inputAddress"
                  placeholder={t('contactForm.conatct_placeHolder_email')}
                  onChange={handleContactChange}
                  required
                />
              </div>

              <div className="col-12 form-row-inner">
                <label htmlFor="inputAddress" className="form-label">
                {t('contactForm.contact_label_phone')}
                </label>
                <div className="iti">
                  <input
                    type="tel"
                    name="phoneNo"
                    value={contactInputs.phoneNo}
                    className="phone-input form-control"
                    placeholder={t('contactForm.conatct_placeHolder_phone')}
                    onChange={handleContactChange}
                    required
                  />
                </div>
              </div>
              <div className="col-12 form-row-inner">
                <label htmlFor="inputAddress" className="form-label">
                {t('contactForm.contact_label_massage')}
                </label>
                <textarea
                  name="massage"
                  value={contactInputs.massage}
                  className="form-control"
                  placeholder={t('contactForm.conatct_placeHolder_massage')}
                  onChange={handleContactChange}
                  required
                ></textarea>
              </div>
              {siteKeys.map((item, id) => (
                <div className="recaptcha" key={id}>
                  <ReCAPTCHA sitekey={item.sitkeyno} onChange={onChange} />
                </div>
              ))}

              {/* <div className="recaptcha" >

                <ReCAPTCHA sitekey="Your client site key" onChange={onChange} />
              </div> */}
              <div className="col-12 form-row-inner">
                <button className="btn btn-danger" type="submit">
                {t('contactForm.contact_btn')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactScreenForm;
