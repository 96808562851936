import React from "react";
import Dashboard from "../../../admin/dashboard/Dashboard_Main";
import "./side_bar.css";
import { Box } from "@mui/material";
import Dashboard_header from "../../../admin/dashboard-header/Dashboard_header";
import Dashboard_page from "../../../dashboard_component/dashboardPage/Dashboard_page";


const Side_bar = () => {
  return (
    <>
    <Dashboard_header/> 
    <Box height={55}/>
      <Box sx={{ display: "flex" }}>
        <Dashboard />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {/* <h1>Dashboard page</h1> */}
          <Dashboard_page/>
        </Box>
      </Box>
    </>
  );
};

export default Side_bar;
