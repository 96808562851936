import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./sign_in.css"
import HeaderNav from "../../header/header_nav";
import Footer from "../../footer/Footer";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const SignInForm = () => {

  const {t} =useTranslation();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMassage, setErrorMassage] = useState("");
  const [searchParams] = useSearchParams();

  const userRef = useRef();
  const errorREf = useRef();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrorMassage("");
  }, [email, password]);

  const handleEmail = (e) => {
    setEmail(e.target.value)
  };
  
  const handlePassword = (e) => {
    setPassword(e.target.value)
  };

  const handleApi = () => {
    console.log({ email, password });
    axios.post('http://cms-api-dev.codemetrics.in/api/v1/AuthManagement/Login', {
      email: email,
      password: password
    })
      .then(result => {
        console.log(result.data)
        // alert('Success')
        localStorage.setItem('token', result.data.token)
        navigate('/dashboard')
      })
      .catch(error => {
        alert('Service error')
        console.log(error)
        if (!error?.response) {
          setErrorMassage("No Server Response");
        } else if (error.response?.status === 400) {
          setErrorMassage("Missing email or Password");
        } else if ("error.respons?.status === 401") {
          setErrorMassage("Unauthorized");
        } else {
          setErrorMassage("Login Failed");
        }
        errorREf.current.focus();
      })
 
  }


  // const [errorMassage, setErrorMassage] = useState("");
  // const [searchParams] = useSearchParams();

  // const userRef = useRef();
  // const errorREf = useRef();

  // useEffect(() => {
  //   userRef.current.focus();
  // }, []);

  // useEffect(() => {
  //   setErrorMassage("");
  // }, [email, password]);

  // const handleLogin = () => {
  //   const data = {
  //     email : email,
  //     password : password
  //   };
  //   const url = 'http://cms-api-dev.codemetrics.in/api/v1/AuthManagement/Login'
  //   axios.post(url,data).then((result) => {
  //     console.log(result.data);
  //     alert('Success')
  //     navigate('/dashboard')
  //   }).catch((error)=>{
  //     console.log(error);
  //       alert("Service error");
  //       if (!error?.response) {
  //         setErrorMassage("No Server Response");
  //       } else if (error.response?.status === 400) {
  //         setErrorMassage("Missing email or Password");
  //       } else if ("error.respons?.status === 401") {
  //         setErrorMassage("Unauthorized");
  //       } else {
  //         setErrorMassage("Login Failed");
  //       }
  //       errorREf.current.focus();

  //   })
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
  };


  const isLogin = searchParams.get("mode") === "login";
  // const data = useActionData();

  return (
    <>
      <HeaderNav />
      <div className="d-flex align-items-center py-4 bg-body-tertiary signin-page">
        <main className="form-signin w-100 m-auto">
          <form onSubmit={handleSubmit}>
            <p
            ref={errorREf}
            className={errorMassage ? "errormasage" : "offscreen"}
            aria-live="assertive"
            id="title"
          >
            {errorMassage}
          </p>
            <h1 className="h2 mb-3 fw-bold">
              {t('signIn.signIn-heading')}
              {/* {isLogin ? "Login" : "Register Now"}  */}
            </h1>
            <p>{t('signIn.signIn_title')}</p>

            <div className="form-floating">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                value={email}
                ref={userRef}
                onChange={handleEmail}
                placeholder="name@example.com"
                required
              />
              <label htmlFor="floatingInput">{t('signIn.signIn_labelEmail')}</label>
            </div>
            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                value={password}
                placeholder="Password"
                onChange={handlePassword}
                required
              />
              <label htmlFor="floatingPassword">{t('signIn.signIn_labelPassword')}</label>
            </div>

            <div className="form-check text-start my-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="remember-me"
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
              {t('signIn.signIn_remember')}
              </label>
            </div>

            <button className=" btn btn-danger w-100 py-2." type="submit"
              onClick={handleApi}
            >{t('signIn.signIn_btn')}
              {/* Sign in  */}
            </button>

            <div className="mt-4">
              <Link
                to={`?mode=${isLogin ? "signup" : "login"}`}
                role="button"
                type="button"
                className="btn-click"
              // onClick={() => setIsLogin((preValue) => !preValue)}
              >
                {/* {isLogin ? "Login?" : "All User"} */}
                {t('signIn.signIn_link')}
              </Link>
            </div>
            {/* <p className="mt-5 mb-3 text-body-secondary">
              © Copywright by Codemetrics | All Rights Reserved.
             </p> */}
          </form>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default SignInForm;


