import { BlogCardProp } from "../../types/component_types";
import avatar from "../../../assets/images/blog-img/user.svg"

const BlogCard = (props: BlogCardProp) => {
  return (
    <>
      <div className="col-xl-4 col-lg-4 col-md-6 col-12">
        <div className="card mb-4 shadow-lg blog-card">
          <a href="/" className="card-img-top">
            <img
              width={354}
              height={235.78}
              src={props.src}
              className="card-img-top rounded-top-md"
              alt="blogpost"
            />
          </a>
          <div className="card-body">
            <a
              href="/"
              className="fs-5 mb-2 fw-semibold d-block text-success"
            >
              {props.subText}
            </a>
            <h3>
              <a href="/" className="text-inherit">
                {props.mainTitle}
              </a>
            </h3>
            <p>{props.cardParagraph}</p>
            <div className="row align-items-center g-0 mt-lg-7 mt-4 blog-avatar">
              <div className="col-auto">
                <img
                  width={35}
                  height={35}
                  src={avatar}
                  alt="avatar"
                  className="rounded-circle avatar-sm me-2"
                />
              </div>
              <div className="col lh-1">
                <h5 className="mb-1">{props.nameTitle}</h5>
                <p className="fs-6 mb-0">{props.dateYear}</p>
              </div>
              <div className="col-auto">
                <p className="fs-6 mb-0">{props.timeRead}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;