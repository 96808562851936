import { Box } from "@mui/material";
import Dashboard from "../../../admin/dashboard/Dashboard_Main";
import Dashboard_header from "../../../admin/dashboard-header/Dashboard_header";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const ApplicationForm = () => {
  const applicationData = [
    {
      id: "1",
      name: "Adit Kumar",
      email: "aditKumar123@gmail.com",
      mobileNo: "9693671658",
      address: "Amnour",
      experince: "1 - 2 years",
      yourSelf: "I am a Softwere Devloper",
      yourCv: "Add your cv",
      workOn: "Remote",
    },
    {
      id: "2",
      name: "Chandan Kumar",
      email: "chandan@gmail.com",
      mobileNo: "9693671658",
      address: "Amnour",
      experince: "1 - 2 years",
      yourSelf: "I am a Softwere Devloper",
      yourCv: "Add your cv",
      workOn: "Onsite",
    },
    {
      id: "3",
      name: "Anshu Kumar",
      email: "chandan@gmail.com",
      mobileNo: "9693671658",
      address: "Amnour",
      experince: "1 - 2 years",
      yourSelf: "I am a Softwere Devloper",
      yourCv: "Add your cv",
      workOn: "Onsite",
    },
    {
      id: "4",
      name: "Anshu Kumar",
      email: "chandan@gmail.com",
      mobileNo: "9693671658",
      address: "Amnour",
      experince: "1 - 2 years",
      yourSelf: "I am a Softwere Devloper",
      yourCv: "Add your cv",
      workOn: "Remote",
    },
  ];

  // const [filter, setFilter] = useState("Remote");
  // const [product, setProduct] = useState(applicationData);

  // useEffect(() => {
  //     if (filter === "Remote") {
  //         setProduct(applicationData);
  //     }
  //     if (filter === "OnSite") {
  //         setProduct(applicationData);
  //     }
  // }, [filter]);

  return (
    <>
      <Dashboard_header />
      <Box height={55} />
      <Box sx={{ display: "flex" }}>
        <Dashboard />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <div className="timeSheet_heading">
            <h1 className="timeSheet_title">Application From</h1>
          </div>
          <div className="timeSheet_continer">
            <div className="timeSheet_wrapper">
              <div className="timesheet">
                <form action="">
                  <div className="formBoxContiner">
                    <div className="formRow">
                      <div className="inputBox">
                        <label htmlFor="" className="formLabel">
                          Employee Name
                        </label>
                        <select
                          name="project"
                          id="projectName"
                          className="optinonValue"
                        >
                          <option value="all">--All Application--</option>
                          <option value="remote">Remote</option>
                          <option value="onsite">Onsite</option>
                        </select>
                      </div>
                      <div className="inputBox">
                        <label htmlFor="form date" className="formLabel">
                          Form Date
                        </label>
                        <input
                          type="date"
                          className="formTitle"
                          placeholder="name"
                          required
                        />
                      </div>
                      <div className="inputBox">
                        <label htmlFor="to date" className="formLabel">
                          To Date
                        </label>
                        <input
                          type="date"
                          className="formTitle"
                          placeholder="name"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="table_section">
            <div className="table_continer">
              <table>
                <thead>
                  <tr>
                    <th>S No</th>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Mobile No</th>
                    <th>Address</th>
                    <th>Years of experience</th>
                    <th>Tell us more about yourself</th>
                    <th>Your CV</th>
                    <th>Worked On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {applicationData.map((item, id) => (
                    <tr key={id}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.mobileNo}</td>
                      <td>{item.address}</td>
                      <td>{item.experince}</td>
                      <td>{item.yourSelf}</td>
                      <td>
                        <Link to="/" target="blank">
                          {item.yourCv}
                        </Link>
                      </td>
                      <td>{item.workOn}</td>
                      <td>
                        <button type="button" className="tableBtn">
                          View
                        </button>
                        <button type="button" className="tableBtnDel">
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default ApplicationForm;
