
// import "../../components/main_global.css"
import "./footer.css"
import { aboutUs, industries, services } from "../dummy-data/dummy_data";
import socialImg1 from "../../assets/images/facebook.svg"
import socialImg2 from "../../assets/images/linkedin.svg"
import socialImg3 from "../../assets/images/youtube.svg"
import socialImg4 from "../../assets/images/twitter.svg"
import { Link } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Footer = () => {

  const onChange = () => { };
  const [showModal, setShowModal] = useState(false);

  const [subcribeData, setSubcribeData] = useState({
    email:""
  })

  const handleSuscribeSubmit = (e: any) => {
    e.preventDeafualt()
    
    setSubcribeData({
      email:"",
    });
  }
  console.log("subcribeData", subcribeData);

  const handleSubscribeChange = (e:any) => {
    setSubcribeData({
      ...subcribeData,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <footer>
      <div className="container">
        <div className="py-5 footer-content">
          <div className="row">
            <div
              className="col-6 col-md-2 mb-3"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              <h5>About</h5>
              <ul className="nav flex-column">

                {aboutUs.map((item) => (
                  <li className="nav-item mb-2" key={item.id}>
                    <Link
                      to={item.sendTo}
                      className="nav-link p-0 text-muted"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div
              className="col-6 col-md-2 mb-3"
              data-aos="fade-right"
              data-aos-duration="700"
            >
              <h5>Industries</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2"> 
                  <Link
                    to="/transportation"
                    className="nav-link p-0 text-muted"
                  >
                    Transportation
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/sass"
                    className="nav-link p-0 text-muted"
                  >
                    SaaS
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/education"
                    className="nav-link p-0 text-muted"
                  >
                    Education
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/finance"
                    className="nav-link p-0 text-muted"
                  >
                    Finance
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/ecommerce"
                    className="nav-link p-0 text-muted"
                  >
                    Ecommerce
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/socialMedia"
                    className="nav-link p-0 text-muted"
                  >
                    Social Media
                  </Link>
                </li>
                {/* {industries.map((item) => (
                  <li className="nav-item mb-2" key={item.id}>
                    <a
                      href={item.sendTo}
                      className="nav-link p-0 text-muted"
                    >
                      {item.title}
                    </a>
                  </li>
                ))} */}
              </ul>
            </div>

            <div
              className="col-6 col-md-2 mb-3"
              data-aos="fade-right"
              data-aos-duration="900"
            >
              <h5>Services</h5>
              <ul className="nav flex-column">
                {services.map((item) => (
                  <li className="nav-item mb-2" key={item.id}>
                    <Link
                      to={item.sendTo}
                      className="nav-link p-0 text-muted"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div
              className="col-md-5 offset-md-1 mb-3"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <form onSubmit={handleSuscribeSubmit}>
                <h5>Subscribe to our newsletter</h5>
                <p className="color-light-white">
                  {"Monthly digest of what's new and exciting from us."}
                </p>
                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                  <label htmlFor="newsletter1" className="visually-hidden">
                    Email address
                  </label>
                  <input
                    id="newsletter1"
                    type="email"
                    name="email"
                    value={subcribeData.email}
                    onChange={handleSubscribeChange}
                    className="form-control"
                    placeholder="Email address"
                    required
                  />
                  <button className="btnOne btn-danger" type="button" onClick={() => setShowModal(true)}>
                    <a
                      style={{ textDecoration: "none", color: "#fff" }}

                    >
                      Subscribe
                    </a>
                  </button>
                </div>
                <ul className="list-unstyled d-flex footer-social-icons">
                  <li>
                    <a
                      href="https://www.facebook.com/"
                      className="social-link"
                      target="_blank"
                    >
                      <img
                        alt="social"
                        width={30}
                        height={30.08}
                        // src="/images/facebook.svg"
                        src={socialImg1}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/"
                      className="social-link"
                      target="_blank"
                    >
                      <img
                        alt="social"
                        width={30}
                        height={30.08}
                        // src="/images/linkedin.svg"
                        src={socialImg2}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.twitter.com/"
                      className="social-link"
                      target="_blank"
                    >
                      <img
                        alt="social"
                        width={30}
                        height={30.08}
                        // src="/images/twitter.svg"
                        src={socialImg4}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/"
                      className="social-link"
                      target="_blank"
                    >
                      <img
                        alt="social"
                        width={30}
                        height={30.08}
                        // src="/images/youtube.svg"
                        src={socialImg3}
                      />
                    </a>
                  </li>
                </ul>
              </form>
            </div>
          </div>

          <div className="d-flex flex-column justify-content-between py-4 my-4 border-top text-center">
            <p className="copyright-text">
              © Copywright by Codemetrics | All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
      {showModal ? (
        <div className="DisApproved_section">
          <div className="Subscribe_continer">
            <div className="DisApproved_icon">
              <Close onClick={() => setShowModal(false)} />
            </div>
            <div className="Subscribe_content">
              <div className="DisApproved_box">
                <h4>Subscribe to our newsletter</h4>
              </div>
              <div className="SubscribeRecaptcha" >

                <ReCAPTCHA
                  sitekey="6LfJzucoAAAAAKVojkUWud3OYtwcmaTRaPw-yABU"
                  onChange={onChange}
                />
              </div>
              <button type="submit" className="subcribebtn" onClick={() => setShowModal(false)}>
                Send
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </footer>
  );
};

export default Footer;