import { Box } from "@mui/material";
import React from "react";
import Dashboard from "../../../admin/dashboard/Dashboard_Main";
import Dashboard_header from "../../../admin/dashboard-header/Dashboard_header";
import "./contact-Details.css";

const ContactDetails = () => {
    return (
        <>
            <Dashboard_header />
            <Box height={55} />
            <Box sx={{ display: "flex" }}>
                <Dashboard />

                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div className="timeSheet_heading">
                        <h1 className="timeSheet_title">All <span className="getTittle">Get in Touch</span> Details</h1>
                    </div>
                    <div className="timeSheet_continer">
                        <div className="timeSheet_wrapper">
                            <div className="contactTimesheet">
                                <form action="">
                                    <div className="formBoxContiner">
                                        <div className="formRow">
                                            <div className="inputBox">
                                                <label htmlFor="month" className="formLabel">
                                                    Month
                                                </label>
                                                <input
                                                    type="month"
                                                    className="formTitle"
                                                    placeholder="name"
                                                    // value={date}
                                                    // onChange={(e) => setDate(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="inputBox">
                                                <label htmlFor="form date" className="formLabel">
                                                    Form Date
                                                </label>
                                                <input
                                                    type="date"
                                                    className="formTitle"
                                                    placeholder="name"
                                                    // value={date}
                                                    // onChange={(e) => setDate(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="inputBox">
                                                <label htmlFor="to date" className="formLabel">
                                                    To Date
                                                </label>
                                                <input
                                                    type="date"
                                                    className="formTitle"
                                                    placeholder="name"
                                                    // value={date}
                                                    // onChange={(e) => setDate(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="detailsSection">
                        <div className="detailsContiner">
                            <div className="employee_timeSheet">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Full Name</th>
                                            <th>Massage</th>
                                            <th>Email</th>
                                            <th>Phone Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Bhanu Partap</td>
                                            <td>Type a Massage Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias natus provident alias!</td>
                                            <td>bhanupartap.codemetrics@gmail.com</td>
                                            <td>91-996584****</td>
                                        </tr>
                                        <tr>
                                            <td>Anshu Maurya</td>
                                            <td>Type a Massage</td>
                                            <td>anshumaurya.codemetrics@gmail.com</td>
                                            <td>91-996584****</td>
                                        </tr>
                                        <tr>
                                            <td>Neeraj Singh</td>
                                            <td>Type a Massage</td>
                                            <td>neerajsingh.codemetrics@gmail.com</td>
                                            <td>91-996584****</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Box>

            </Box>
        </>
    );
};

export default ContactDetails;
