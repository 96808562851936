import React, { useState } from "react";
// import "../../main_global.css";
// import { LiaRadiationSolid } from "react-icons/lia";
// import { AiOutlineCloudUpload } from "react-icons/ai";
// import { BsRecycle } from "react-icons/bs";
// import { LiaPodcastSolid } from "react-icons/lia";
import SecurityCard from "./security_card";
import CustomPopUp from "../custom-pop-up/custom_pop_up";
import { AiFillFolderOpen, AiOutlineCloudUpload } from "react-icons/ai";
import "./manage_security.css";
import { BsRecycle } from "react-icons/bs";
import { LiaPodcastSolid } from "react-icons/lia";
import { useTranslation } from "react-i18next";

const ManageSecurity = () => {
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="about-content">
        <div className="container px-4 py-5">
          <h2 className="pb-2 aboutTitle">
            {t("aboutManageSecurity.aboutContent_aboutTitle")}
          </h2>
          <p className="aboutSubTitle">
            {t("aboutManageSecurity.aboutcontent_aboutSubTitle")}
          </p>

          <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
            <div className="col d-flex flex-column align-items-start gap-2">
              <h2 className="managedTitle">
                {t("aboutManageSecurity.about_managedTitle")}
              </h2>
              <p className="managedSubTitle">
                {t("aboutManageSecurity.about_managedSubTitle")}
              </p>
              <button
                onClick={() => setShowForm(true)}
                className="btnClick"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                {t("aboutManageSecurity.about_managed_btn")}
              </button>
              {showForm && <CustomPopUp onClick={() => setShowForm(false)} />}
            </div>

            <div className="col">
              <div className="row row-cols-1 row-cols-sm-2 g-4">
                <SecurityCard
                  //   icon={<LiaRadiationSolid />}
                  icon={<AiFillFolderOpen />}
                  cardText={t(
                    "managed_Security.managed_securityCard_cardTextOne"
                  )}
                  cardSubText={t(
                    "managed_Security.managed_securityCard_cardSubTextOne"
                  )}
                />

                <SecurityCard
                  icon={<AiOutlineCloudUpload />}
                  // icon={<AiFillFolderOpen/>}
                  cardText={t(
                    "managed_Security.managed_securityCard_cardTextTwo"
                  )}
                  cardSubText={t(
                    "managed_Security.managed_securityCard_cardSubTextTwo"
                  )}
                />

                <SecurityCard
                  icon={<BsRecycle />}
                  // icon={<AiFillFolderOpen/>}
                  cardText={t(
                    "managed_Security.managed_securityCard_cardTextThree"
                  )}
                  cardSubText={t(
                    "managed_Security.managed_securityCard_cardSubTextThree"
                  )}
                />

                <SecurityCard
                  icon={<LiaPodcastSolid />}
                  // icon={<AiFillFolderOpen/>}
                  cardText={t(
                    "managed_Security.managed_securityCard_cardTextFour"
                  )}
                  cardSubText={t(
                    "managed_Security.managed_securityCard_cardSubTextFour"
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageSecurity;
