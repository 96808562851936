import React from "react";
// import "../../main_global.css";
import BlogCard from "./blogcard";
import photo from "../../../assets/images/blog-img/2.jpg";
import photo1 from "../../../assets/images/blog-img/3.jpg";
import photo2 from "../../../assets/images/blog-img/4.jpg";
import photo3 from "../../../assets/images/blog-img/5.jpg";
import photo4 from "../../../assets/images/blog-img/6.jpg";
import photo5 from "../../../assets/images/blog-img/7.jpg";
import photo6 from "../../../assets/images/blog-img/8.jpg";
import photo7 from "../../../assets/images/blog-img/9.jpg";
import photo8 from "../../../assets/images/blog-img/10.jpg";
import photo9 from "../../../assets/images/blog-img/1.jpg";
import "./blog_page.css";
import avatar from "../../../assets/images/blog-img/user.svg";
import { useTranslation } from "react-i18next";
// import avatar1 from "../../../assets/images/blog-img/user.svg"
// import avatar2 from "../../../assets/images/blog-img/user.svg"
// import avatar3 from "../../../assets/images/blog-img/user.svg"
// import avatar4 from "../../../assets/images/blog-img/user.svg"
// import avatar5 from "../../../assets/images/blog-img/user.svg"
// import avatar6 from "../../../assets/images/blog-img/user.svg"
// import avatar7 from "../../../assets/images/blog-img/user.svg"
// import avatar8 from "../../../assets/images/blog-img/user.svg"
// import avatar9 from "../../../assets/images/blog-img/user.svg"

const BlogPage = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <main> */}
      <section className="pb-8 blog-container">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <ul className="nav nav-underline blog-tabs">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">
                    {t("blogPage.blogPage_active")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    {t("blogPage.blogPage_all")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    {t("blogPage.blogPage_course")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    {t("blogPage.blogPage_workshop")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    {t("blogPage.blogPage_tutorial")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    {t("blogPage.blogPage_company")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="card mb-4 shadow-lg blog-card">
                <div className="row g-0">
                  <img
                    width={742.66}
                    height={289.69}
                    src={photo9}
                    alt="blog"
                    className="col-lg-8 col-md-12 col-12 bg-cover img-left-rounded"
                  />

                  <div className="col-lg-4 col-md-12 col-12">
                    <div className="card-body">
                      <a href="/" className="fs-5 mb-3 fw-semibold d-block">
                        {t('blogCard_link.blogCard_link_course')}
                      </a>
                      <h1 className="mb-2 mb-lg-4">
                        <a href="/" className="text-inherit">
                        {t('blogCard_link.blogCard_link_javaScript')}
                        </a>
                      </h1>
                      <p>
                      {t('blogCard_link.blogCard_link_subTitle')}
                      </p>
                      <div className="row align-items-center g-0 mt-lg-7 mt-4 blog-avatar">
                        <div className="col-auto">
                          <img
                            width={35}
                            height={35}
                            src={avatar}
                            alt="avatar"
                            className="rounded-circle avatar-sm me-2"
                          />
                        </div>
                        <div className="col lh-1">
                          <h5 className="mb-1">{t('blogCard_link.blogCard_heading')}</h5>
                          <p className="fs-6 mb-0">{t('blogCard_link.blogCard_date')}</p>
                        </div>
                        <div className="col-auto">
                          <p className="fs-6 mb-0">{t('blogCard_link.blogCard_time')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BlogCard
              src={photo}
              subText={t('blogCard.blogCard_subText')}
              mainTitle={t('blogCard.blogCard_mainTitle')}
              cardParagraph={t('blogCard.blogCard_cardParagraph')}
              nameTitle={t('blogCard.blogCard_nameTitle')}
              dateYear={t('blogCard.blogCard_dateYear')}
              timeRead={t('blogCard.blogCard_timeRead')}
            />

            <BlogCard
              src={photo1}
              subText={t('blogCardTwo.blogCard_subText')}
              mainTitle={t('blogCardTwo.blogCard_mainTitle')}
              cardParagraph={t('blogCardTwo.blogCard_cardParagraph')}
              nameTitle={t('blogCardTwo.blogCard_nameTitle')}
              dateYear={t('blogCardTwo.blogCard_dateYear')}
              timeRead={t('blogCardTwo.blogCard_timeRead')}
            />

            <BlogCard
              src={photo2}
              subText={t('blogCardThree.blogCard_subText')}
              mainTitle={t('blogCardThree.blogCard_mainTitle')}
              cardParagraph={t('blogCardThree.blogCard_cardParagraph')}
              nameTitle={t('blogCardThree.blogCard_nameTitle')}
              dateYear={t('blogCardThree.blogCard_dateYear')}
              timeRead={t('blogCardThree.blogCard_timeRead')}
            />

            <BlogCard
              src={photo3}
              subText={t('blogCardFour.blogCard_subText')}
              mainTitle={t('blogCardFour.blogCard_mainTitle')}
              cardParagraph={t('blogCardFour.blogCard_cardParagraph')}
              nameTitle={t('blogCardFour.blogCard_nameTitle')}
              dateYear={t('blogCardFour.blogCard_dateYear')}
              timeRead={t('blogCardFour.blogCard_timeRead')}
            />

            <BlogCard
              src={photo4}
              subText={t('blogCardFive.blogCard_subText')}
              mainTitle={t('blogCardFive.blogCard_mainTitle')}
              cardParagraph={t('blogCardFive.blogCard_cardParagraph')}
              nameTitle={t('blogCardFive.blogCard_nameTitle')}
              dateYear={t('blogCardFive.blogCard_dateYear')}
              timeRead={t('blogCardFive.blogCard_timeRead')}
            />

            <BlogCard
              src={photo5}
              subText={t('blogCardSix.blogCard_subText')}
              mainTitle={t('blogCardSix.blogCard_mainTitle')}
              cardParagraph={t('blogCardSix.blogCard_cardParagraph')}
              nameTitle={t('blogCardSix.blogCard_nameTitle')}
              dateYear={t('blogCardSix.blogCard_dateYear')}
              timeRead={t('blogCardSix.blogCard_timeRead')}
            />

            <BlogCard
              src={photo6}
              subText={t('blogCardSeven.blogCard_subText')}
              mainTitle={t('blogCardSeven.blogCard_mainTitle')}
              cardParagraph={t('blogCardSeven.blogCard_cardParagraph')}
              nameTitle={t('blogCardSeven.blogCard_nameTitle')}
              dateYear={t('blogCardSeven.blogCard_dateYear')}
              timeRead={t('blogCardSeven.blogCard_timeRead')}
            />

            <BlogCard
              src={photo7}
              subText={t('blogCardEight.blogCard_subText')}
              mainTitle={t('blogCardEight.blogCard_mainTitle')}
              cardParagraph={t('blogCardEight.blogCard_cardParagraph')}
              nameTitle={t('blogCardEight.blogCard_nameTitle')}
              dateYear={t('blogCardEight.blogCard_dateYear')}
              timeRead={t('blogCardEight.blogCard_timeRead')}
            />

            <BlogCard
              src={photo8}
              subText={t('blogCardNine.blogCard_subText')}
              mainTitle={t('blogCardNine.blogCard_mainTitle')}
              cardParagraph={t('blogCardNine.blogCard_cardParagraph')}
              nameTitle={t('blogCardNine.blogCard_nameTitle')}
              dateYear={t('blogCardNine.blogCard_dateYear')}
              timeRead={t('blogCardNine.blogCard_timeRead')}
            />
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center mt-4">
              <a href="/blog" className="btn btn-primary">
                <div
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                {t('blogLoad.blogLoad_btn')}
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* </main> */}
    </>
  );
};

export default BlogPage;
