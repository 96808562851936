import { Link } from "react-router-dom";
import { WorkInteroductions } from "../../types/component_types";
// import "../../main_global.css"
import React from "react";
import "./work-continer.css"

const WorkInteroduction = (props: WorkInteroductions) => {
  return (
    <>
      <div className="case-study-row">
        <div className="case-info">
          <label
            data-aos="fade-up"
            data-aos-duration="500"
            className="aos-init aos-animate"
          >
            {props.smallText}
          </label>
          <Link
            to={props.link}
            target="_blank"
            className="web-link aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            {props.companyLink}
          </Link>
          <h3
            data-aos="fade-up"
            data-aos-duration="700"
            className="aos-init aos-animate"
          >
            {props.maxText}
          </h3>
          <p
            data-aos="fade-up"
            data-aos-duration="800"
            className="aos-init aos-animate"
          >
            {props.middleText}
          </p>
        </div>
        <div 
        className={props.bgImg}
        >
          <img
            alt="workIntro"
            width={393}
            height={706}
            src={props.src}
            data-aos="flip-left"
            data-aos-duration="1000"
            className="aos-init aos-animate"
          />
        </div>
      </div>
    </>
  );
};

export default WorkInteroduction;
