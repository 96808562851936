import React from 'react'
import SliderView from '../../screens/landingPage/slider/slider_view'
// import Client from '../../screens/landingPage/clients/clients'
// import AdDetails from '../../screens/landingPage/advertisement/addetails'
import Delivervalue from '../../screens/landingPage/advertisement/deliver_value'
import MemberCard from '../../screens/landingPage/member-view/member_card'
import ContactForm from '../../reusableScreens/contact-form/contact_form'
import "../../../components/screens/landingPage/main-page.css"
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Client from '../../screens/landingPage/clients/Clients'
import AdDetails from '../../screens/landingPage/advertisement/AdDetails'
import HeaderNav from '../../header/header_nav'
import Footer from '../../footer/Footer'

const Home = () => {
  return (
    <div>
      <HeaderNav/>
      <SliderView/>
      <Client/>
      <AdDetails/>
      <Delivervalue/>
      <MemberCard/>
      <ContactForm/>
      <Footer/>
    </div>
  )
}

export default Home
