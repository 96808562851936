import React from "react";
import SecBanner from "../../reusableScreens/sec-banner/sec_banner";
import WorkInteroduction from "../../reusableScreens/work-continer/workinteroduction";
import { BsArrowRight } from "react-icons/bs"; 
import { GiCircle } from "react-icons/gi";
import WorkInteroductioLeft from "../../reusableScreens/work-continer/work_interoduction_left";
import ContactForm from "../../reusableScreens/contact-form/contact_form";

import workInteroductionImg from "../../../assets/images/e-monitor-bg.png";
import workInteroductionImg2 from "../../../assets/images/wave-bg.png";
import workInteroductionImg3 from "../../../assets/images/informa-bg.png";
import HeaderNav from "../../header/header_nav";
import Footer from "../../footer/Footer";
import { useTranslation } from "react-i18next";

const Work = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderNav />
      <SecBanner
        pageTitle={t("work.secbanner_title")}
        headingText={t("work.secbanner_headingText")}
        headingTextBold={t("work.secbanner_headdingText_bold")}
        subTitle={t("work.secbanner_subTitle")}
      />
      <WorkInteroduction
        smallText={t('workInteroduction.workInteroduction_smallText')}
        companyLink="www.mm-dev.com"
        maxText={t('workInteroduction.workInteroduction_maxText')}
        middleText={t('workInteroduction.workInteroduction_middleText')}
        link="http://mm-dev.codemetrics.in"

        src={workInteroductionImg}
        bgImg="case-screen iflip-bg"
      />

      <WorkInteroductioLeft
        src={workInteroductionImg3}
        smallText={t('workInteroductionLeft.workInteroductionLeft_smallText')}
        companyLink="www.informasystems.com"
        maxText={t('workInteroductionLeft.workInteroductionLeft_maxText')}
        middleText={t('workInteroductionLeft.workInteroductionLeft_middleText')}
        icons={<BsArrowRight />}
        iconsOne={<GiCircle fontSize={45} />}
      />
      <WorkInteroduction
        smallText={t('workInteroductionTwo.workInteroduction_smallText')}
        companyLink="www.waveindustries.com"
        maxText={t('workInteroductionTwo.workInteroduction_maxText')}
        middleText={t('workInteroductionTwo.workInteroduction_middleText')}
        
        src={workInteroductionImg2}
        bgImg="case-screen diesel-bg"
        link="http://waveindustries.in"

      />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Work;
