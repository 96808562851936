import React from "react";
import "./jobs.css";
import HeaderNav from "../../header/header_nav";
import SecBanner from "../../reusableScreens/sec-banner/sec_banner";
import { useTranslation } from "react-i18next";
import JobsForm from "../../jobs/JobsForm";
import Footer from "../../footer/Footer";

const Jobs = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderNav />
      <div className="jobsMain-page-header job-bg">
        <div className="container">
          <div className="jobs-head-inner">
            <div className="jobs-head-info">
              <label
                data-aos="fade-up"
                data-aos-duration="500"
                className="aos-init aos-animate"
              >
                Jobs List
              </label>
              <h2
                data-aos="fade-up"
                data-aos-duration="600"
                className="aos-init aos-animate"
              >
                <span>Built In Job Skills</span>
                An overview of the job responsibilities
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        
          <form className="jobForm">
            <input type="text" placeholder="search now" className="formItem" />
            <button type="submit" className="search_btn">
              Search
            </button>
          </form>
        </div>
      
      
      <JobsForm />
      <Footer/>
    </>
  );
};

export default Jobs;
