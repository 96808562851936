import React, { useEffect, useState } from "react";
import "../../components/main_global.css";
import photo from "../../assets/images/logo.png";
import CustomPopUp from "../reusableScreens/custom-pop-up/custom_pop_up";
import nationalImg1 from "../../assets/images/3253487_flag_liberia_country_world_icon.svg";
import { HiMenuAlt3, HiX } from "react-icons/hi";
import { Link } from "react-router-dom";
import "./header.css";
import { NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "flag-icon-css/css/flag-icons.css";
import { Close } from "@mui/icons-material";
import i18next, { changeLanguage } from "i18next";
import { BsGlobe } from "react-icons/bs";
// import cookies from "js-cookie"

const HeaderNav = () => {
  // const currentLangaugeCode = cookies.get('i18next') || 'en'
  // const currentLang = languages.find(l => l.code === currentLangaugeCode)

  const [showForm, setShowForm] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [fix, setFix] = useState("fixed-top");
  window.onscroll = () => {
    let temp;
    let top = window.scrollY;
    if (top > 100) {
      temp = "fixed-top sticked";
    } else {
      temp = "fixed-top";
    }
    return setFix(temp);
  };

  const languages = [
    {
      code: "en",
      countryName: "English",
      countryCode: "gb",
    },
    {
      code: "ge",
      countryName: "German",
      countryCode: "de",
    },
    {
      code: "sp",
      countryName: "Spanish",
      countryCode: "es",
    },
  ];

  // const list = document.querySelector(".list");
  // const selected = document.querySelector(".selected");
  // const selectedImg = document.querySelector(".selectedImg");
  // if (list) {
  //   list.addEventListener("click", (e) => {
  //     const img = e.target.querySelector("img");
  //     const text = e.target.querySelector(".text")
  //     selectedImg.src = img.src
  //     selected.innerHTML = text.innerHTML
  //   });
  // }

  return (
    <header className={fix}>
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img
                // src="../assets/images/logo1.png"
                src={photo}
                alt="logo"
                width={225}
                height={58}
                // priority
              />
            </Link>
            <div
              className={
                openMenu
                  ? "activemenu"
                  : "collapse navbar-collapse justify-content-end"
              }
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mb-2 mb-lg-0">
                <li
                  className="nav-item-list"
                  data-aos="fade-left"
                  data-aos-duration="300"
                >
                  <Link className="nav-link" aria-current="page" to="/work">
                    Work
                  </Link>
                </li>

                <li
                  className="nav-item-list"
                  data-aos="fade-left"
                  data-aos-duration="400"
                >
                  <Link className="nav-link" to="/industries">
                    Industries
                  </Link>
                </li>
                <li
                  className="nav-item-list"
                  data-aos="fade-left"
                  data-aos-duration="400"
                >
                  <Link className="nav-link" to="/jobs">
                    Job
                  </Link>
                </li>

                <li
                  className="nav-item-list dropdown"
                  data-aos="fade-left"
                  data-aos-duration="500"
                >
                  {/* <Services /> */}
                  <NavDropdown
                    title="Services"
                    id="basic-nav-dropdown"
                    className=" mb-2 mb-lg-0"
                  >
                    <NavDropdown.Item className="nav-item">
                      <Link to="/services/mobiledev" className="nav-link">
                        Mobile App Development
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item className="nav-item">
                      <Link to="/services/webDev" className="nav-link">
                        Web Development
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item className="nav-item">
                      <Link to="/services/uiUxDesign" className="nav-link">
                        UI/UX Designing
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item className="nav-item">
                      <Link to="/services/mobGames" className="nav-link">
                        Mobile Games
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item className="nav-item">
                      <Link to="/services/softTesting" className="nav-link">
                        Software Testing and QA
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item className="nav-item">
                      <Link to="/services/maintenance" className="nav-link">
                        Manage Maintenance Services
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                </li>
                <li
                  className="nav-item-list"
                  data-aos="fade-left"
                  data-aos-duration="600"
                >
                  <Link className="nav-link" to="/contactUs">
                    Contact Us
                  </Link>
                </li>

                <li
                  className="nav-item-list"
                  data-aos="fade-left"
                  data-aos-duration="600"
                >
                  <button
                    onClick={() => setShowForm(true)}
                    className="btnClick btnClickLeft"
                    data-aos="fade-left"
                    data-aos-duration="700"
                  >
                    Get in Touch
                  </button>
                </li>

                {showForm && <CustomPopUp onClick={() => setShowForm(false)} />}
                <li
                  className="nav-item-list signBtn"
                  data-aos="fade-left"
                  data-aos-duration="600"
                >
                  <Link
                    to="/signIn"
                    className="btn btnLeft btn-outline-danger"
                    data-aos="fade-left"
                    data-aos-duration="800"
                  >
                    Sign In
                  </Link>
                </li>

                <div className="dropdown">
                  <button
                    className="btn btnLeft"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {/* <BsGlobe  width={27} height={27}/> */}
                    <img alt="logo" width={27} height={27} src={nationalImg1} />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {languages.map(({ code, countryName, countryCode }) => (
                      <li key={countryCode}>
                        <button
                          className="dropdown-item"
                          onClick={() => i18next.changeLanguage(code)}
                          // disabled={code === currentLangaugeCode}
                        >
                          <span
                            className={`flag-icon flag-icon-${countryCode} mx-2`}
                          ></span>
                          {/* <img
                          // src="/images/German.svg"
                          src={nationalImg4}
                          alt="german"
                          className="Country"
                          width={30}
                          height={30}
                        /> */}
                          {countryName}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </ul>
            </div>

            <div className="mobile_nav_box">
              <HiMenuAlt3
                name="menu-outline"
                className="mobile_nav_menu"
                onClick={() => setOpenMenu(true)}
              />
              <Close
                name="close-outline"
                className="close-outline mobile_nav_menu"
                onClick={() => setOpenMenu(false)}
              />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default HeaderNav;
