import clientImg1 from "../../../../assets/images/Merkle.png"
import clientImg2 from "../../../../assets/images/wave-logo.svg"
import clientImg3 from "../../../../assets/images/informa.png"
import clientImg4 from "../../../../assets/images/SoftEmpower.png"
import "./client.css"
const Client = () => {
  return (
    <div className="clients-info">
      <div className="container">
        <ul className="clients-logos">
          <li>
            <img
              alt="client"
              width={140}
              height={45}
              src={clientImg1}
            />
          </li>
          <li>
            <img
              alt="client"
              width={121.3}
              height={40}
              src={clientImg2}
            />
          </li>
          <li>
            <img
              alt="client"
              width={140}
              height={45}
              src={clientImg3}
            />
          </li>
          <li>
            <img
              alt="client"
              width={140}
              height={45}
              src={clientImg4}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Client;
