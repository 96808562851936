import React from "react";
import Dashboard_header from "../../../admin/dashboard-header/Dashboard_header";
import { Box } from "@mui/material";
import Dashboard from "../../../admin/dashboard/Dashboard_Main";
import "./employee.css";
const Employee_timeSheet = () => {
  const option = [
    {
      value: "all",
      tittle: "All",
    },
    {
      value: "bhanu",
      tittle: "Bhanu Partap",
    },
    {
      value: "anshu",
      tittle: "Anshu Maurya",
    },
    {
      value: "neeraj",
      tittle: "Neeraj Singh",
    },
  ];

//   const employeeDetails = [
//     {
//       id: "1",
//       date: "27-01-2024",
//       firstDescription: "DescriptionOne",
//       secondDescription: "DescriptionTwo",
//       thirdDescription: "DescriptionThree",
//       firstTime: "3:00Hr",
//       secondTime: "2:00Hr",
//       thirdTime: "3:00Hr",
//       total: "8:00Hr",
//     },
//   ];

  return (
    <>
      <Dashboard_header />
      <Box height={55} />
      <Box sx={{ display: "flex" }}>
        <Dashboard />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <div className="timeSheet_heading">
            <h1 className="timeSheet_title">Employee time Sheet</h1>
          </div>
          <div className="timeSheet_continer">
            <div className="timeSheet_wrapper">
              <div className="employee_timeSheet">
                <form action="">
                  <div className="formBoxContiner">
                    <div className="formRow">
                      <div className="inputBox">
                        <label htmlFor="month" className="formLabel">
                          Month
                        </label>
                        <input
                          type="month"
                          className="formTitle"
                          placeholder="name"
                          // value={date}
                          // onChange={(e) => setDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="inputBox">
                        <label htmlFor="" className="formLabel">
                          Employee Name
                        </label>
                        <select
                          name="project"
                          id="projectName"
                          className="optinonValue"
                          // onChange={handleSelect}
                        >
                          {option.map((option, id) => (
                            <option value={option.value} key={id}>
                              {option.tittle}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="inputBox">
                        <label htmlFor="form date" className="formLabel">
                          Form Date
                        </label>
                        <input
                          type="date"
                          className="formTitle"
                          placeholder="name"
                          // value={date}
                          // onChange={(e) => setDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="inputBox">
                        <label htmlFor="to date" className="formLabel">
                          To Date
                        </label>
                        <input
                          type="date"
                          className="formTitle"
                          placeholder="name"
                          // value={date}
                          // onChange={(e) => setDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="employee_timeSheet_continer">
            <div className="employee_timesheet_wrapper">
              <div className="employee_timeSheet">
                <h3>Bhanu Partap</h3>
                <table>
                  <thead className="emplloyeeTable">
                    <tr>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Hours</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr>
                      <td rowSpan={3}>27-01-2024</td>
                      <td>Description lorem10</td>
                      <td>3:00Hr</td>
                      <td rowSpan={3}>8:00Hr</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>3:00Hr</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>3:00Hr</td>
                    </tr>
                    <tr>
                      <td rowSpan={3}>27-01-2024</td>
                      <td>Description</td>
                      <td>3:00Hr</td>
                      <td rowSpan={3}>8:00Hr</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>3:00Hr</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>3:00Hr</td>
                    </tr>
                    <tr>
                      <td rowSpan={3}>27-01-2024</td>
                      <td>Description</td>
                      <td>3:00Hr</td>
                      <td rowSpan={3}>8:00Hr</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>3:00Hr</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>3:00Hr</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Employee_timeSheet;
