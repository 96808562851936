
import React from "react";
import SecBanner from "../../reusableScreens/sec-banner/sec_banner";
import ManageSecurity from "../../reusableScreens/manage-security/manage_security";
import Delivervalue from "../../screens/landingPage/advertisement/deliver_value";
// import Information from "../../faqs/information";
import MemberCard from "../../screens/landingPage/member-view/member_card";
import ContactForm from "../../reusableScreens/contact-form/contact_form";
import Information from "../../FAQs/Information";
import HeaderNav from "../../header/header_nav";
import Footer from "../../footer/Footer";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const {t} = useTranslation();
  return (
    <>
    <HeaderNav/>
      <SecBanner
        pageTitle={t('aboutUs.aboutus_secBanner_pageTitle')}
        headingText={t('aboutUs.aboutUs_secBanner_headingtext')}
        headingTextBold={t('aboutUs.aboutUs_secBanner_heading_title')}
        subTitle={t('aboutUs.aboutUs_secBanner_subTitle')}
      />
      <ManageSecurity />
      <Delivervalue />

      <Information/>

      <MemberCard />
      <ContactForm />
      <Footer/>
    </>
  );
};

export default AboutUs;
