import React from "react";
import { CardProps } from "../types/component_types";

const ContactCard = (props: CardProps) => {
  return (
    <div className="col-md-6 col-xl-4">
      <div className="card card-body shadow text-center align-items-center h-100">
        <div className="icon-lg bg-info bg-opacity-10 text-info rounded-circle mb-2">
          <i className="las la-id-card">{props.icon}</i>
        </div>
        <h5>{props.title}</h5>
        <p>{props.subTitle}</p>
        <div className="d-grid gap-3 d-sm-block">
          <a href="#">
            <i className="las la-phone"></i>

            {props.contacts}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;