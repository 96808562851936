import React, { useState } from "react";
import { LiaDonateSolid } from "react-icons/lia";
import { PiInfinity } from "react-icons/pi";
import "./contact-form.css";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation();


  const [contactFormInputs, setContactFormInputs] = useState({
    name: "",
    email: "",
    phoneNo: "",
    massage: "",
  });

  const handleContactFormChange = (e) => {
    setContactFormInputs({
      ...contactFormInputs,
      [e.target.name]: e.target.value,
    });
  };

  const [contactFormDataTable, setContactFormDataTable] = useState([]);

  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    setContactFormDataTable([...contactFormDataTable, contactFormInputs]);

    setContactFormInputs({
      name: "",
      email: "",
      phoneNo: "",
      massage: "",
    });
  };

  console.log("contactFormTableData", contactFormDataTable);

  return (
    <>
      <div className="contact-container">
        <div className="container">
          <h3 data-aos="fade-right">{t("contactForm.contactForm_heading")}</h3>
          <p data-aos="fade-right">{t("contactForm.contactForm_title")}</p>
        </div>
      </div>
      <div className="contact-form">
        <div className="container">
          <div className="form-inner">
            <div className="company-experience">
              <div className="experience-item" data-aos="fade-right">
                <div className="experience-icon">
                  <i className="las la-donate">
                    <LiaDonateSolid
                      fontSize={33}
                      color="red"
                      className="icon"
                    />
                  </i>
                </div>
                <div className="experience-details">
                  <h5>{t("contactForm.contactForm_detials_headingOne")}</h5>
                  <p>{t("contactForm.contact_details_titleOne")}</p>
                </div>
              </div>

              <div className="experience-item" data-aos="fade-right">
                <div className="experience-icon">
                  <i className="las la-infinity">
                    <PiInfinity fontSize={33} color="red" className="icon" />
                  </i>
                </div>
                <div className="experience-details">
                  <h5>{t("contactForm.contactForm_detials_headingTwo")}</h5>
                  <p>{t("contactForm.contact_details_titleTwo")}</p>
                </div>
              </div>

              <div className="experience-item" data-aos="fade-right">
                <div className="experience-icon">
                  <i className="las la-donate">
                    <LiaDonateSolid
                      fontSize={33}
                      color="red"
                      className="icon"
                    />
                  </i>
                </div>
                <div className="experience-details">
                  <h5>{t("contactForm.contactForm_detials_headingThree")}</h5>
                  <p>{t("contactForm.contact_details_titleThree")}</p>
                </div>
              </div>
            </div>
            <div
              className="company-contact-form"
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
            >
              <h3>{t("contactForm.contact_touch")}</h3>
              <p>{t("contactForm.contact_touch_title")}</p>

              <form
                className="form-container"
                onSubmit={handleContactFormSubmit}
              >
                <div className="col-12 form-row-inner">
                  <label htmlFor="inputAddress" className="form-label">
                    {t("contactForm.contact_label_name")}
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={contactFormInputs.name}
                    className="form-control"
                    id="inputAddress"
                    placeholder={t("contactForm.conatct_placeHolder_name")}
                    onChange={handleContactFormChange}
                    required
                  />
                </div>

                <div className="col-12 form-row-inner">
                  <label htmlFor="inputAddress" className="form-label">
                    {t("contactForm.contact_label_email")}
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={contactFormInputs.email}
                    className="form-control"
                    id="inputAddress"
                    placeholder={t("contactForm.conatct_placeHolder_email")}
                    onChange={handleContactFormChange}
                    required
                  />
                </div>
                <div className="col-12 form-row-inner">
                  <label htmlFor="inputAddress" className="form-label">
                    {t("contactForm.contact_label_phone")}
                  </label>
                  <input
                    type="text"
                    name="phoneNo"
                    value={contactFormInputs.phoneNo}
                    className="form-control"
                    id="inputAddress"
                    placeholder={t("contactForm.conatct_placeHolder_phone")}
                    onChange={handleContactFormChange}
                    required
                  />
                </div>
                <div className="col-12 form-row-inner">
                  <label htmlFor="inputAddress" className="form-label">
                    {t("contactForm.contact_label_massage")}
                  </label>
                  <textarea
                    className="form-control"
                    name="massage"
                    value={contactFormInputs.massage}
                    placeholder={t("contactForm.conatct_placeHolder_massage")}
                    onChange={handleContactFormChange}
                    required
                  ></textarea>
                </div>

                <div className="col-12 form-row-inner">
                  <button className="btn btn-danger" type="submit">
                    {t("contactForm.contact_btn")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
