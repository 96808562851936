import React, { useState } from "react";
// import "../../landingPage/main-page.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../reusableScreens/custom-pop-up/popup.css";
import CustomPopUp from "../../../reusableScreens/custom-pop-up/custom_pop_up";
import { swipper } from "../../../dummy-data/dummy_data";
import BgImage from "./bg_image";
import { useTranslation } from "react-i18next";
import i18next from "i18next"; 

// import { swipper } from "../../../types/component_types";
// import video from "../../../../assets/logo/video/background-video"
const SliderView = () => {
  const [showForm, setShowForm] = useState(false);
  const {t} = useTranslation();
  return (
    <>
      <Swiper
        id="carouselExampleCaptions"
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <div
          slot="container-start"
          className="parallax-bg"
          data-swiper-parallax="-23%"
        >
          {/* <video src="/video/background-video.mp4" autoPlay muted loop /> */}
          <BgImage/>
        </div>

        <SwiperSlide className="swiper-slide">
            <div className="text">
              <div
                className="type"
                data-swiper-parallax="-500"
                data-aos="fade-right"
              >
                <h1>{t('swipper.our_minimum_bar_for_client_delivery')}</h1>
              </div>

              <div
                className="textBox"
                data-swiper-parallax="-300"
                data-aos="fade-right"
              >
                <p>{t('swipper.codemetrics_infotech_is_a_customer-centric')}</p>
              </div>
              <button
                onClick={() => setShowForm(true)}
                className="push"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                {t('swipper.button')}
              </button>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="text">
              <div
                className="type"
                data-swiper-parallax="-500"
                data-aos="fade-right"
              >
                <h1>{t('swipper.custom_application')}</h1>
              </div>

              <div
                className="textBox"
                data-swiper-parallax="-300"
                data-aos="fade-right"
              >
                <p>{t('swipper.codemetrics_infotech_is_a_customer-centric')}</p>
              </div>
              <button
                onClick={() => setShowForm(true)}
                className="push"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                {t('swipper.button')}
              </button>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="text">
              <div
                className="type"
                data-swiper-parallax="-500"
                data-aos="fade-right"
              >
                <h1>{t('swipper.your_digital')}</h1>
              </div>

              <div
                className="textBox"
                data-swiper-parallax="-300"
                data-aos="fade-right"
              >
                <p>{t('swipper.codemetrics_infotech_is_a_customer-centric')}</p>
              </div>
              <button
                onClick={() => setShowForm(true)}
                className="push"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                {t('swipper.button')}
              </button>
            </div>
          </SwiperSlide>

        {/* {swipper.map((item: any) => (
          <SwiperSlide className="swiper-slide" key={item.id}>
            <div className="text">
              <div
                className="type"
                data-swiper-parallax="-500"
                data-aos="fade-right"
              >
                <h1>{item.heading}</h1>
              </div>

              <div
                className="textBox"
                data-swiper-parallax="-300"
                data-aos="fade-right"
              >
                <p>{item.subHeading}</p>
              </div>
              <button
                onClick={() => setShowForm(true)}
                className="push"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                {"Let's Discuss Your Project"}
              </button>
            </div>
          </SwiperSlide>
        ))} */}
      </Swiper>
      {showForm && <CustomPopUp onClick={() => setShowForm(false)} />}
    </>
  );
};

export default SliderView;
