import Card from "../../../cards/card";
 import React from "react";
import "./addetails.css";
import { useTranslation } from "react-i18next";
import cardImg1 from "../../../../assets/images/mobile-app.png";
import cardImg2 from "../../../../assets/images/web-app.svg"
import cardImg3 from "../../../../assets/images/ux-design.svg"
import cardImg4 from "../../../../assets/images/mobile-game.svg"
import cardImg5 from "../../../../assets/images/testing.svg"
import cardImg6 from "../../../../assets/images/manage-apps.svg"
const AdDetails = () => {
  const { t } = useTranslation();

  return (
    <div className="services-container">
      <div className="container px-4 py-5">
        <h2 className="pb-2 fw-bold" data-aos="fade-right">
          {t("adDetails.adDetails_tittle")}
        </h2>
        <p className="team-title-head service-subtitle" data-aos="fade-right">
          {t("adDetails.adDetails_hedding")}
        </p>

        <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
          <Card
            src={cardImg1}
            serviceTitle={t('cardOne.card_serviceTitle')}
            subTitle={t('cardOne.card_subTitle')}
            sendto="/services/mobileDev" 
          />
          <Card
            src={cardImg2}
            serviceTitle={t('cardTwo.card_serviceTitle')}
            subTitle={t('cardTwo.card_subTitle')}
            sendto="/services/webDev"
          />
          <Card
            src={cardImg3}
            serviceTitle={t('cardThree.card_serviceTitle')}
            subTitle={t('cardThree.card_subTitle')}
            sendto="/services/uiUxDesign"
          />
          <Card
            src={cardImg4}
            serviceTitle={t('cardFour.card_serviceTitle')}
            subTitle={t('cardFour.card_subTitle')}
            sendto="/services/mobGames"
          />
          <Card
            src={cardImg5}
            serviceTitle={t('cardFive.card_serviceTitle')}
            subTitle={t('cardFive.card_subTitle')}
            sendto="/services/softTesting"
          />
          <Card
            src={cardImg6}
            serviceTitle={t('cardSix.card_serviceTitle')}
            subTitle={t('cardSix.card_subTitle')}
            sendto="/services/maintenance"
          />
          {/* {homeCard.map((item: any) => {
            return (
              
              <Card
                key={item.id}
                src={item.src}
                serviceTitle={item.serviceTitle}
                subTitle={item.subTitle}
                sendto={item.sendto}
              />
              
            );
          })} */}
        </div>
      </div>
    </div>
  );
};

export default AdDetails;
