import React from 'react'
// import Dashboard from '../../admin/dashboard/Dashboard_Main'
import "./dashboard_main.css"
import Dashboard_Main from '../../admin/dashboard/Dashboard_Main'

const Dashboard = () => {
  return (
    <div className='main_page'>
      <div className='main_page_section'>
        <Dashboard_Main />
      </div>
      <div className="main_product_page">
        <div className="main_product_tittle">
          <div className="main_product_head">
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;
