
import { SocialContacts } from "../types/component_types";

const SocialContact = (props: SocialContacts) => {
  return (
    <>
      <div className="col-md-6 col-xl-4">
        <div className="card card-body shadow text-center align-items-center h-100">
          <div className="icon-lg bg-info bg-opacity-10 text-info rounded-circle mb-2">
            <i className="las la-id-card">{props.icon}</i>
          </div>
          <h5>{props.title}</h5>
          <p>{props.subTitle}</p>
          <div className="d-grid gap-3 d-sm-block">
            <a href="#">
              <i className="las la-phone"></i>
            </a>

            <ul className="list-inline mb-0">
              <li>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  className="social-link"
                >
                  <img
                    alt="social"
                    width={30}
                    height={30}
                    src={props.facebookIcon}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  className="social-link"
                >
                  <img
                    alt="social"
                    width={30}
                    height={30}
                    src={props.linkedinIcon}
                  />
                </a>
                <a
                  href="https://www.twitter.com/"
                  target="_blank"
                  className="social-link"
                >
                  <img
                    alt="social"
                    width={30}
                    height={30}
                    src={props.twitterIcon}
                  />
                </a>
                <a
                  href="https://www.youtube.com/"
                  target="_blank"
                  className="social-link"
                >
                  <img
                    alt="social"
                    width={30}
                    height={30}
                    src={props.youtubeIcon}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialContact;