import ContactCard from "./contact_card";
import SocialContact from "./social_contact";
import socialImg1 from "../../assets/images/facebook.svg"
import socialImg2 from "../../assets/images/linkedin.svg"
import socialImg3 from "../../assets/images/youtube.svg"
import socialImg4 from "../../assets/images/twitter.svg"
import { AiOutlineMail } from "react-icons/ai";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdAddchart } from "react-icons/md";
import { useTranslation } from "react-i18next";

const ContactCardDetails = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="connect-information">
        <div className="container">
          <div className="row g-4">
            <ContactCard
              // icon={<RiContactsBookLine />}
              icon={<BiSolidPhoneCall/>}
              title={t('contactCard.contactCard_title')}
              subTitle={t('contactCard.contactCard_subTitle')}
              contacts="+91-9953208269"

            />

            <ContactCard
              // icon={<MdOutlineMarkEmailUnread />}
              icon={<AiOutlineMail/>}
              title={t('contactCardTwo.contactCard_title')}
              subTitle={t('contactCardTwo.contactCard_subTitle')}
              contacts="info@codemetrics.in"
            />

            <SocialContact
              icon={<MdAddchart />}
              facebookIcon={socialImg1}
              linkedinIcon={socialImg2}
              twitterIcon={socialImg4}
              youtubeIcon={socialImg3}
              title={t('socialCard.contactCard_title')}
              subTitle={t('socialCard.contactCard_subTitle')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactCardDetails;