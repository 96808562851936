import memberImg1 from "../../assets/images/dummy-person.png"
import memberImg2 from "../../assets/images/dummy-person.png"
import memberImg3 from "../../assets/images/dummy-person.png"

import cardImg1 from "../../assets/images/mobile-app.png"
import cardImg2 from "../../assets/images/web-app.svg"
import cardImg3 from "../../assets/images/ux-design.svg"
import cardImg4 from "../../assets/images/mobile-game.svg"
import cardImg5 from "../../assets/images/testing.svg"
import cardImg6 from "../../assets/images/manage-apps.svg"
//landing page cards

export const homeCard = [
    {
      id: 1,
      // src: "/images/mobile-app.png",
      src: cardImg1,
      serviceTitle: "Mobile App",
      subTitle:
        "Mobile app developers have been creating iOS and Android apps for so many years that it's not just their passion",
      sendto: "/services/mobileDev",
    },
  
    {
      id: 2,
      // src: "/images/web-app.svg",
      src: cardImg2,
      serviceTitle: "Web Application",
      subTitle:" We've built mobile backend systems as well as web applications across numerous industry verticals",
  
      sendto: "/services/webDev",
    },
  
    {
      id: 3,
      // src: "/images/ux-design.svg",
      src: cardImg3,
      serviceTitle: "UI/UX Designing",
      subTitle:"Design is always a fundamental contributor to the experience of digital products. Even when you don't have one",
  
      sendto: "/services/uiUxDesign",
    },
  
    {
      id: 4,
      // src: "/images/mobile-game.svg",
      src: cardImg4,
      serviceTitle: "Mobile Games",
      subTitle:"The gaming industry is at rise like never before and it's not surprising why app ecosystems have dedicated their focus",
  
      sendto: "/services/mobGames",
    },
  
    {
      id: 5,
      // src: "/images/testing.svg",
      src: cardImg5,
      serviceTitle: "Software Testing",
      subTitle:"Software testing is a crucial for software development project. As a software company with more than 5 years",
  
      sendto: "/services/softTesting",
    },
  
    {
      id: 6,
      // src: "/images/manage-apps.svg",
      src: cardImg6,
      serviceTitle: "Maintenance Services",
      subTitle:"As mature problems-solvers, we have over a five year's of experience in tackling challenging business-critical projects",
  
      sendto: "/services/maintenance",
    },
  ];
  
  //member card


  export const member = [
    {
      id: 1,
      src: memberImg1,
      memberName: "Jaya Lakshmi",
      memberRole: "Director",
    },
    {
      id: 2,
      src: memberImg2,
      memberName: "Priyanka Mishra",
      memberRole: "Resource and Finance Manager",
    },
    {
      id: 3,
      src: memberImg3,
      memberName: "Pallavi Mishra",
      memberRole: "QA and Lead Project",
    },
  ];
  
  //swipper
  
  export const swipper = [
    {
      id: 1,
      heading: "Our minimum bar for client delivery.",
      subHeading:
        "Codemetrics Infotech is a customer-centric software and web development company specializing in delivering the right solutions for small and medium businesses.",
    },
    {
      id: 2,
      heading: "Custom Application Development Services.",
      subHeading:
        "Codemetrics Infotech is a customer-centric software and web development company specializing in delivering the right solutions for small and medium businesses.",
    },
    {
      id: 3,
      heading: "Your digital product starts here.",
      subHeading:
        "Codemetrics Infotech is a customer-centric software and web development company specializing in delivering the right  solutions for small and medium businesses.",
    },
  ];
  
  //footer
  export const aboutUs = [
    {
      id: 1,
      sendTo: "/aboutUs",
      title: "About Us",
    },
    {
      id: 2,
      sendTo: "/work",
      title: "Case Studies",
    },
    {
      id: 3,
      sendTo: "/career",
      title: "Career",
    },
    {
      id: 4,
      sendTo: "/blog",
      title: "Blog",
    },
    {
      id: 5,
      sendTo: "/contactUs",
      title: "Contact Us",
    },
  ];
  
  export const industries = [
    {
      id: 1,
      sendTo: "/transportation",
      title: "Transportation",
    },
    {
      id: 2,
      sendTo: "/sass",
      title: "SaaS",
    },
    {
      id: 3,
      sendTo: "/education",
      title: "Education",
    },
    {
      id: 4,
      sendTo: "/finance",
      title: "Finance",
    },
    {
      id: 5,
      sendTo: "/ecommerce",
      title: "Ecommerce",
    },
    {
      id: 6,
      sendTo: "/socialMedia",
      title: " Social Media",
    },
    // {
    //   sendTo: "/industries",
    //   title: "All Industries",
    // },
  ];
  
  export const services = [
    {
      id: 1,
      sendTo: "/services/mobileDev",
      title: "Mobile App Development",
    },
    {
      id: 2,
      sendTo: "/services/webDev",
      title: "Web Development",
    },
    {
      id: 3,
      sendTo: "/services/uiUxDesign",
      title: "UI/UX Designing",
    },
    {
      id: 4,
      sendTo: "/services/mobGames",
      title: "Mobile Games",
    },
    {
      id: 5,
      sendTo: "/services/softTesting",
      title: "Software Testing and QA",
    },
    {
      id: 6,
      sendTo: "/services/maintenance",
      title: " Manage Maintenance Services",
    },
  ];