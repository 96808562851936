import React, { useState } from "react";
// import "../../../components/main_global.css";
import "./carrer-screen.css"
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

const CarrerScreenForm = () => {
  const {t} = useTranslation();
  const onChange = () => { };

  const [carrerInputs, setCarrerInputs] = useState({
    name: "",
    email: "",
    mobileNo: "",
    address: "",
    experience: "",
    message: "",
    cv: "",
    remote: "You'd like to work remotely"

  })

  const handleCarrerChange = (e) => {
    setCarrerInputs({
      ...carrerInputs,
      [e.target.name]: e.target.value,

    });
  };

  const [carrerTableData, setCarrerTableData] = useState([]);

  const handleCarrerSubmit = (e) => {
    e.preventDefault();
    // console.log("carrerInputs", carrerInputs);
    setCarrerTableData([...carrerTableData, carrerInputs])

    
    setCarrerInputs({
      name: "",
      email: "",
      mobileNo: "",
      address: "",
      experience: "",
      message: "",
      cv: "",
      remote: ""
    })
  }
  console.log("carrerTableData", carrerTableData)

  return (
    <>
      {/* <main> */}
      <section className="carrerSection">
        <div className="container">
          <form className="career-form-container" onSubmit={handleCarrerSubmit} >
            <div className="container">
              <div className="career-form-inner">
                <div className="row">
                  <div className="col-lg-4 col-sm-12 career-bg"></div>
                  <div className="col-lg-8 col-sm-12">
                    <div className="career-form-main">
                      <h3>{t('carrerScreenForm.carreScreenform_Heading')}</h3>
                      <p>{t('carrerScreenForm.carrerScreenForm_title')}</p>
                      <div className="row">
                        <label className="d-block mb-4 col-sm-6">
                          <span className="form-label d-block">
                          {t('carrerScreenForm.carrerScreenForm_name')}
                          </span>
                          <input
                            required
                            name="name"
                            value={carrerInputs.name}
                            type="name"
                            className="form-control"
                            placeholder={t('carrerScreenForm.carrerScreenForm_placeHolderName')}
                            onChange={handleCarrerChange}
                          />
                        </label>

                        <label className="d-block mb-4 col-sm-6">
                          <span className="form-label d-block">
                          {t('carrerScreenForm.carrerScreeForm_email')}
                          </span>
                          <input
                            name="email"
                            value={carrerInputs.email}
                            type="email"
                            className="form-control"
                            placeholder={t('carrerScreenForm.carrerScreenForm_placeHolderEmail')}
                            onChange={handleCarrerChange}
                          />
                        </label>
                      </div>
                      <div className="row">
                        <label className="d-block mb-4 col-sm-6">
                          <span className="form-label d-block">
                          {t('carrerScreenForm.carrerScreenForm_mobile')}
                          </span>
                          <input
                            required
                            name="mobileNo"
                            value={carrerInputs.mobileNo}
                            type="mobileno"
                            className="form-control"
                            placeholder={t('carrerScreenForm.carrerScreenForm_placeHolderMobile')}
                            onChange={handleCarrerChange}
                          />
                        </label>
                        <label className="d-block mb-4 col-sm-6">
                          <span className="form-label d-block">
                          {t('carrerScreenForm.carrerScreeForm_address')}
                          </span>
                          <input
                            required
                            name="address"
                            value={carrerInputs.address}
                            type="address"
                            className="form-control"
                            placeholder={t('carrerScreenForm.carrerScreenForm_placeHolderAddress')}
                            onChange={handleCarrerChange}
                          />
                        </label>
                      </div>
                      <label className="d-block mb-4">
                        <span className="form-label d-block">
                        {t('carrerScreenForm.careerScreenForm_year')}
                        </span>
                        <select
                          required
                          name="experience"
                          value={carrerInputs.experience}
                          className="form-select"
                          onChange={handleCarrerChange}
                        >
                          <option>{t('carrerScreenForm.optionOne')}</option>
                          <option>{t('carrerScreenForm.optionTwo')}</option>
                          <option>{t('carrerScreenForm.optionThree')}</option>
                          <option>{t('carrerScreenForm.optionFour')}</option>
                          <option>{t('carrerScreenForm.optionFive')}</option>
                          <option>{t('carrerScreenForm.optionSix')}</option>
                        </select>
                      </label>

                      <label className="d-block mb-4">
                        <span className="form-label d-block">
                        {t('carrerScreenForm.carrerScreenForm_about')}
                        </span>
                        <textarea
                          name="message"
                          value={carrerInputs.message}
                          className="form-control"
                          // rows="3"
                          placeholder={t('carrerScreenForm.carrerScreenForm_placeHolderAbout')}
                          onChange={handleCarrerChange}
                        ></textarea>
                      </label>

                      <label className="d-block mb-4">
                        <span className="form-label d-block">{t('carrerScreenForm.carrerScreenform_cv')}</span>
                        <input
                          required
                          name="cv"
                          value={carrerInputs.cv}
                          type="file"
                          className="form-control"
                          onChange={handleCarrerChange}
                        />
                      </label>

                      <div className="mb-4">
                        <div>
                          <div className="form-check">
                            <label className="d-block">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="remote"
                                value="You'd like to work remotely"
                                onChange={handleCarrerChange}
                                // value="yes"
                                checked={carrerInputs.remote==="You'd like to work remotely"}
                              />
                              <span className="form-check-label">
                              {t('carrerScreenForm.carrerScreenForm_remote')}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div>
                          <label className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="remote"
                              value="You'd prefer to work onsite"
                              onChange={handleCarrerChange}
                            // value="no"
                            checked={carrerInputs.remote=== "You'd prefer to work onsite"}
                            />
                            <span className="form-check-label">
                            {t('carrerScreenForm.carrerScreenForm_onsite')}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="recaptcha" >
                        <ReCAPTCHA
                          sitekey="6LfJzucoAAAAAKVojkUWud3OYtwcmaTRaPw-yABU"
                          onChange={onChange}
                        />
                      </div>
                      <div className="mb-3 mt-3">
                        <button
                          type="submit"
                          className="btn btn-danger px-3 rounded-2"
                        >
                          {t('carrerScreenForm.carrerScreenForm_btn')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      {/* </main> */}
    </>
  );
};

export default CarrerScreenForm;
