import React from 'react'
import "./search.css"
// import CvImage from "../../../assets/cv/5192055.jpg"
const Search = () => {
  return (
    <>
    
    {/* <img src={CvImage} alt="Cv" /> */}
    </>
  )
}

export default Search
