
import ServicesBanner from "../../../reusableScreens/service-banner/services_banner";
import Management from "../../../reusableScreens/management/management";
import ContactForm from "../../../reusableScreens/contact-form/contact_form";
import servicsImg from "../../../../assets/images/manage-service.png"
import HeaderNav from "../../../header/header_nav";
import Footer from "../../../footer/Footer";
import { useTranslation } from "react-i18next";

const Maintenance = () => {
  const {t} = useTranslation();
  return (
    <>
    <HeaderNav/>
      <ServicesBanner
        headingText={t('servicesMaintenance.maintenance_servicesBanner_headingText')}
        subHeading={t('servicesMaintenance.maintenance_servicesBanner_subHeading')}
        src={servicsImg}
      />
      <Management />
      <ContactForm />
      <Footer/>
    </>
  );
};

export default Maintenance;

