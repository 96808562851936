import React from 'react'
import videobg from "../../../../assets/logo/video/background-video.mp4"
import "./bg-image.css"
const BgImage= () => {
  return (
    <div>
      <video src={videobg} autoPlay muted loop className='video_bg' />
    </div>
  )
}

export default  BgImage
