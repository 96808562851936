import { WorkInteroductioLefts } from "../../types/component_types";
import {Link} from "react-router-dom"
// import "../../main_global.css"
import "./work-continer.css"
const WorkInteroductioLeft = (props: WorkInteroductioLefts) => {
  return (
    <>
      <div className="case-study-row align-right">
        <div className="case-screen merkle-bg">
          <img
            alt="workIntroImg"
            width={393}
            height={706}
            src={props.src}
            data-aos="flip-left"
            data-aos-duration="1000"
            className="aos-init aos-animate"
          />
        </div>
        <div className="case-info">
          <label
            data-aos="fade-up"
            data-aos-duration="500"
            className="aos-init aos-animate"
          >
            {props.smallText}
          </label>
          <Link
            to="https://www.informasystems.com/"
            target="_blank"
            className="web-link aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            <i className="las la-link"></i>
            {props.companyLink}
          </Link>
          <h3
            data-aos="fade-up"
            data-aos-duration="700"
            className="aos-init aos-animate"
          >
            {props.maxText}
          </h3>
          <p
            data-aos="fade-up"
            data-aos-duration="800"
            className="aos-init aos-animate"
          >
            {props.middleText}
          </p>
        </div>
      </div>
    </>
  );
};

export default WorkInteroductioLeft;
