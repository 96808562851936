import React from "react";
import Dashboard_header from "../../admin/dashboard-header/Dashboard_header";
import { Box, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  AccessTime,
  ArrowForwardIos,
  CallToAction,
  CheckCircle,
  Dashboard,
  SignLanguage,
  TipsAndUpdates,
} from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import "./dashboard_page.css";

const Dashboard_page = () => {
  return (
    <div>
      <Dashboard_header />
      <Box height={10} />
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <h3 className="dashboard_title">
            Welcome back,<span className="heighlight_title"> D</span>ashboard{" "}
            <SignLanguage />
          </h3>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Stack spacing={5} direction="row">
                <Card
                  sx={{ minWidth: 31 + "%", height: 120 }}
                  className="icon_card"
                >
                  <CardContent>
                    <div className="top_side">
                      <div className="icon_button">
                        <AccessTime />
                      </div>
                      <div className="top_right">
                        <Typography gutterBottom variant="h5" component="div">
                          Hours Spent
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <h3 className="icon_tittle">34hr</h3>
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card
                  sx={{ minWidth: 31 + "%", height: 120 }}
                  className="icon_card"
                >
                  <CardContent>
                    <div className="top_side">
                      <div className="icon_button_one">
                        <TipsAndUpdates />
                      </div>
                      <div className="top_right">
                        <Typography gutterBottom variant="h5" component="div">
                          Test Results
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <h3 className="icon_tittle_one">82%</h3>
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>

                <Card
                  sx={{ minWidth: 31 + "%", height: 120 }}
                  className="icon_card"
                >
                  <CardContent>
                    <div className="top_side">
                      <div className="icon_button_two">
                        <CheckCircle />
                      </div>
                      <div className="top_right">
                        <Typography gutterBottom variant="h5" component="div">
                          Project Completed
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <h3 className="icon_tittle_two">140</h3>
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <Card sx={{ maxWidth: 345 }}>
                  <Stack spacing={2} direction="row">
                    <div className="iconstyle">
                      <CallToAction />
                    </div>
                    <div className="paddingall">
                      <span className="pricetitle">$120k</span>
                      <br />
                      <span className="pricesubtitle">Total Work</span>
                    </div>
                  </Stack>

                  {/* <div className="top_side">
                      <div className="icon_button_two">
                        <CheckCircle />
                      </div>
                      <div className="top_right"></div>
                    </div> */}
                  {/* <Typography gutterBottom variant="h5" component="div">
                      Course Completed
                    </Typography> */}
                </Card>
                <Card sx={{ maxWidth: 345 }}>
                  <Stack spacing={2} direction="row">
                    
                  </Stack>
                </Card>
              </Stack>
            </Grid>
          </Grid>
          <Box height={20} />
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Card sx={{ height: 52 + "vh" }} className="charts">
                <CardContent>
                  <div className="work_continer">
                    <h4 className="work_title">Topic you are intersted in</h4>
                  </div>
                  <div className="chart_continer">
                    <div className="chart_value_continer">
                      <div className="ui">
                        <div className="ui_style" value="7">
                          <h4 className="ui_title_style">UI Design</h4>
                        </div>

                        <div className="ui_head">100%</div>
                      </div>
                      <div className="ux">
                        <div className="ux_style" value="6">
                          <h4 className="ui_title_style">UX Design</h4>
                        </div>
                        <div className="ux_head">90%</div>
                      </div>
                      <div className="ui">
                        <div className="mobile_game_style" value="5">
                          <h4 className="ui_title_style">Mobile Game</h4>
                        </div>
                        {/* <div className="mob_head">70%</div> */}
                      </div>
                      <div className="ui">
                        <div className="mobile_app_style" value="4">
                          <h4 className="ui_title_style">Mobile App</h4>
                        </div>
                        {/* <div className="ui_head">70%</div> */}
                      </div>
                      <div className="ui">
                        <div className="web_app_style" value="3">
                          <h4 className="ui_title_style">Web App</h4>
                        </div>
                        {/* <div className="ui_head">70%</div> */}
                      </div>
                      <div className="ui">
                        <div className="softwere_testing_style" value="2">
                          <h4 className="ui_title_style">Testing</h4>
                        </div>
                        {/* <div className="ui_head">70%</div> */}
                      </div>
                      <div className="ui">
                        <div className="application_testing_style" value="1">
                          <h4 className="ui_title_style">Application</h4>
                        </div>
                        {/* <div className="ui_head">70%</div> */}
                      </div>
                      <div className="percentage_number">
                        <span className="percentage_num_heading">10%</span>
                        <span className="percentage_num_heading">20%</span>
                        <span className="percentage_num_heading">40%</span>
                        <span className="percentage_num_heading">50%</span>
                        <span className="percentage_num_heading">65%</span>
                        <span className="percentage_num_heading">85%</span>
                        <span className="percentage_num_heading">100%</span>
                      </div>
                    </div>
                    <div className="work_right_continer">
                      <div className="right_section">
                        <div className="ui_box_continer">
                          <h3 className="ui_box_title">UI Design</h3>
                          <p className="ui_subtitle">100%</p>
                        </div>
                        <div className="ux_box_continer">
                          <h3 className="ux_box_title">UX Design</h3>
                          <p className="ui_subtitle">90%</p>
                        </div>
                      </div>
                      <div className="right_section">
                        <div className="web_app_continer">
                          <h3 className="web_box_title">Web App</h3>
                          <p className="ui_subtitle">85%</p>
                        </div>
                        <div className="mobile_game_continer">
                          <h3 className="mobile_box_title">Mobile Game</h3>
                          <p className="ui_subtitle">65%</p>
                        </div>
                      </div>
                      <div className="right_section">
                        <div className="mobile_app_continer">
                          <h3 className="mobile_app_title">Mobile App</h3>
                          <p className="ui_subtitle">65%</p>
                        </div>
                        <div className="testing_continer">
                          <h3 className="testing_title">Testing</h3>
                          <p className="ui_subtitle">65%</p>
                        </div>
                      </div>
                      <div className="application_continer"></div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ height: 52 + "vh" }} className="charts">
                <CardContent>
                  <div className="work_continer">
                    <h4 className="work_title">Assignment Progress</h4>
                  </div>
                  <div className="progress_bar_continer">
                    <div className="progress_br_wrapper">
                      <div className="progress_wrapper">
                      <div className="progress_number">
                        <span className="precentage_bar">95%</span>
                      </div>
                      <div className="progress_heading">
                        <h3 className="progress_title">User exprience Design</h3>
                        <p className="progress_subtitle">120Task</p>
                      </div>
                      </div>
                      <div className="progress_arrow">
                        <div className="progress_icon">
                          <ArrowForwardIos/>
                        </div>
                      </div>
                    </div>
                    <div className="progress_br_wrapper">
                      <div className="progress_wrapper">
                      <div className="progress_number">
                        <span className="precentage_bar_react">85%</span>
                      </div>
                      <div className="progress_heading">
                        <h3 className="progress_title">React, React netive Component</h3>
                        <p className="progress_subtitle">40Task</p>
                      </div>
                      </div>
                      <div className="progress_arrow">
                        <div className="progress_icon">
                          <ArrowForwardIos/>
                        </div>
                      </div>
                    </div>
                    <div className="progress_br_wrapper">
                      <div className="progress_wrapper">
                      <div className="progress_number">
                        <span className="precentage_bar_angular">55%</span>
                      </div>
                      <div className="progress_heading">
                        <h3 className="progress_title">Angular framework</h3>
                        <p className="progress_subtitle">220Task</p>
                      </div>
                      </div>
                      <div className="progress_arrow">
                        <div className="progress_icon">
                          <ArrowForwardIos/>
                        </div>
                      </div>
                    </div>
                    <div className="progress_br_wrapper">
                      <div className="progress_wrapper">
                      <div className="progress_number">
                        <span className="precentage_bar_net">45%</span>
                      </div>
                      <div className="progress_heading">
                        <h3 className="progress_title">.Net Backend</h3>
                        <p className="progress_subtitle">420Task</p>
                      </div>
                      </div>
                      <div className="progress_arrow">
                        <div className="progress_icon">
                          <ArrowForwardIos/>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard_page;
