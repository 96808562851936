import { SecurityCards } from "../../types/component_types";
import React from "react";

const SecurityCard = (props: SecurityCards) => {
  return (
    <div className="col d-flex flex-column gap-2">
      <div className="feature-icon-small d-inline-flex align-items-center justify-content-center needs-item-about">
        <i className="las la-radiation">{props.icon}</i>
      </div>
      <h4 className="fw-semibold mb-0 text-body-emphasis">{props.cardText}</h4>
      <p className="text-body-secondary securitySubtitle">
        {props.cardSubText}
      </p>
    </div>
  );
};

export default SecurityCard;