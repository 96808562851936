import React from "react";
// import "../../components/main_global.css";
import { SecBanners } from "../../types/component_types";
import "./sec-banner.css"
const SecBanner = (props: SecBanners) => {
  return (
    <>
      <div className="work-page-header">
        <div className="container">
          <div className="work-head-inner">
            <div className="work-head-info">
              <label
                data-aos="fade-up"
                data-aos-duration="500"
                className="aos-init aos-animate"
              >
                {props.pageTitle}
              </label>
              <h2
                data-aos="fade-up"
                data-aos-duration="600"
                className="aos-init aos-animate"
              >
                <span>{props.headingText}</span>
                {props.headingTextBold}
              </h2>
              <p
                data-aos="fade-up"
                data-aos-duration="800"
                className="aos-init"
              >
                {props.subTitle}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecBanner;
