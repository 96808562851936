import Footer from "../../footer/Footer";
import HeaderNav from "../../header/header_nav";
import ContactForm from "../../reusableScreens/contact-form/contact_form";
import IndustriesBanner from "../../reusableScreens/industries/industries_banner";
import ManageSecurity from "../../reusableScreens/manage-security/manage_security";
import { useTranslation } from "react-i18next";

const Finance = () => {
  const {t} = useTranslation();
  return (
    <>
    <HeaderNav/>
      <IndustriesBanner
        workStyle="work-page-header contact-head industries-bg finance-bg"
        contactTitle={t('finance.finance_contactTitle')}
        contactHeading={t('finance.finance_contactHeading')}
        contactSubheading={t('finance.finance_contactSubheading')}
      />
      <ManageSecurity />
      <ContactForm />
      <Footer/>
    </>
  );
};

export default Finance;
